.checklist {
  display: flex;
  flex-direction: column;
  height: fit-content;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  background-color: #fff;
  padding: 10px;
  border-radius: 10px;
  -webkit-box-shadow: 0px 5px 100px 18px rgba(37, 39, 41, 0.2);
  -moz-box-shadow: 0px 5px 100px 18px rgba(37, 39, 41, 0.2);
  box-shadow: 0px 5px 100px 18px rgba(37, 39, 41, 0.2);
  max-width: 600;
  margin: auto;
  bottom: 0;
  max-height: 90vh;
  position: relative;
  overflow-y: auto;

  @media (max-width: 640px) {
    width: 90vw;
   
  }

  &__menu {
    padding: 30px 60px;
    padding: 30px 40px;

    @media (max-width: 640px) {
      padding: 15px 15px;
    }

    &__element {
      display: flex;
      align-items: center;
      padding-bottom: 50px;

      &__logo {
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        border-radius: 50%;
        background-color: rgb(212, 235, 241);
        width: 110px;
        height: 110px;

        &__icon {
          width: 55px;
        }
        &__down_icon {
          width: 13px;
        }
      }

      &__description {
        padding-left: 20px;
        font-family: serif;
        max-width: 410px;

        &__header {
          font-size: 30px;
          font-weight: 700;
        }

        &__input {
          border: none;
          border-bottom: 3px solid black;
          width: 258px;

          &:focus {
            outline: none;
            border-bottom: 3px solid rgb(70, 112, 119);
            -webkit-box-shadow: 0px 87px 97px -27px rgba(82, 140, 184, 0.47);
            -moz-box-shadow: 0px 87px 97px -27px rgba(82, 140, 184, 0.47);
            box-shadow: 0px 87px 97px -27px rgba(82, 140, 184, 0.47);
          }
        }

        &__dropdown {
          width: 150px;
          height: 2rem;
          display: flex;
          justify-content: space-between;
          align-items: center;
          box-shadow: 0px 5px 10px 7px rgba(202, 214, 223, 0.26);
          padding: 0 9px;
          border-radius: 5px;

          &:hover {
            background: #cfe7eb;
            cursor: pointer;
          }

          &__text {
            margin: 0;
          }
        }

        &__text {
          font-size: 20px;
        }
      }
    }
  }

  &__submit {
    border: none;
    outline: none;
    width: 30%;
    display: flex;
    justify-content: center;
    justify-self: center;
    margin: auto;
    align-items: center;
    margin-bottom: 25px;
    background-color: #e6e6e6;
    border-radius: 25px;
    box-shadow: none !important;
    padding: 10px;

    &:hover:enabled {
      background: #cfe7eb;
      cursor: pointer;
    }

    &:focus {
      outline: none;
    }
  }
}

.close_button {
  align-self: flex-end;
  border: none;
  border-radius: 40px;
  background-color: #dadada;
  width: 50px;
  height: 50px;
  position: absolute;
  right: 10px;
  top: 10px;

  &:focus {
    outline: none;
  }
}

.completed {
  background-color: #cbfff2;
}

.invalid_email {
  border-bottom: 3px solid red;

  &__text {
    position: absolute;
    color: red;
  }
}

.safety-card {
  // height: 790px;
  height: auto;
  width:720px;
  @media (max-width: 640px) {
    height: auto;
  }
}


.x-button {
  position:absolute;
  right:0;
  top: 5px;
}

.outer-heart {
  position:absolute;
  left:0;
  top:0;


}

.inner-heart {
  position:absolute;
  z-index:0;
  left:9;
  top:9;

}

.hearts {
  position:relative;
  margin-left:100px;
}

.popup-design {
  // position:relative;
  // margin-top:50px;
  // margin-left:240px;
  // height:275px;
  display: flex;
  justify-content: center;
  @media (max-width: 640px) {
    height: auto;
    margin-left:0 !important;
    // margin-top: 0 !important;
    display: flex;
    align-items: center;
  }
}
.harm-feeling-modal{
  padding-right: 0 !important;
}
.modal-header-logo img.fbn-logo-self-harm{
  width: 36%;
}
.modal-dialog-centered.modal-center{
  display: flex;
  justify-content: center;
}
.illustration {
  // height: 220px;
  height: 11rem;
  @media (max-width: 640px) {
    height: 110px;
    flex: 1;
  }
}


.left-wrist {
  position:absolute;
  top:185;
  left:59;

}

.whole-left-hand {
  position:absolute;
  left:40;
  top:38;
}

.right-hand {
  position:absolute;

}

.right-wrist {
  position:absolute;
  top:185px;


}

.whole-right-hand {
  position:absolute;
  top:38px;
  left:175px;
}

.harm-title {
  font-family: "Tiempos Headline";
  font-style: normal;
  font-weight: normal;
  font-size: 38px;
  line-height: 38px;
  text-align: center;
  color: #0BB8C8;
  @media (max-width: 640px) {
    font-size: 26px;
  }
}

.harm-text {
  font-family: "CircularStdBook";
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 26px;
  text-align: center !important;
  margin-top:20px;
  color: #50597C;
  width:90%;
  margin-left:33px;
  @media (max-width: 640px) {
    font-size: 14px;
    width: auto;
    margin-left: 0;
  }
}

.entire-checkmark {
  position:relative;
  margin-bottom:14.5px;
  @media (max-width: 640px) {
    display: flex;
  }
}

.checkmark-checkmark {
  position:absolute;
  left:8px;
  top:2px;
}

.checkmark-list {
  margin-left:36px;
  margin-top:25px;
  z-index:3;
}

.checkmark-text {
  margin-left:13px;
  display:inline-block;
  font-family: "CircularStdBook";
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 16px;
  color: #151852;
  @media (max-width: 640px) {
    font-size: 14px;
    margin-left:8px;
  }
}

.checkmark {
  height: 30px;
  @media (max-width: 640px) {
    height: 21px;
  }
}

.im-okay-button {
  position: absolute;
  width: 230px;
  height: 48px;
  left:243px;
  margin: 5px 0;
  // top:690px;

  border:none;
  background: linear-gradient(180deg, #33CA8F 0%, #0BB8C8 100%);
  box-shadow: 0px 8px 18px rgba(51, 202, 143, 0.18);
  border-radius: 4px;

  
  font-family: "CircularStdBook";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  color: #FFFFFF;
  @media (max-width: 640px) {
    position: static;
    z-index: 1;
  }
}
.modal-open .modal{
  @media (max-width: 640px) {
    padding-right: 0 !important;
  }
}

.safety-left-ellipse {
  position:absolute;
  bottom:0;
  left:0;
}

.safety-right-ellipse {
  position:absolute;
  bottom:0;
  left:36.6;
  opacity: 0.3;
.logo {
  width:250;
  height:48;

}
}

.logo-line {
  display: flex;
  justify-content: space-between;
  align-items: center;
  // margin-left:20px;
  // margin-top:-10px;
  @media (max-width: 640px) {
    // margin-left: 0px;
  }
}

.close-btn-send-action-plan {
 cursor: pointer;
 width: 24px;
 padding: 5px;
}

.share-action-plan {
  font-size: 24px;
  font-weight: 500;
  text-align: left;
  line-height: 34px;
  letter-spacing: 0em;
  
  color: #151852;
}

.words-above-box {
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  color: #50597C;
  margin-bottom: 20px;
  @media (max-width: 640px) {
    margin-left: 0px;
  }
}
.modal-footer {
  @media (max-width: 640px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: .75rem;
  }
}
.modal-mail {
  width:28px;
  height:21px;
  margin-top:-60px;
}

.send-button-top, .send-button-bottom {

  // position: absolute;
  // width: 108px;
  // height: 44px;
  // left: 563px;
  // top: 224px;
  color:white;
  align-self: flex-end;
  padding: 4px 20px;
  border-color:transparent;
  background: linear-gradient(180deg, #33CA8F 0%, #0BB8C8 100%);
  border-radius: 4px;
  z-index:2;
  position: absolute;
  top: 0;
  height: 100%;
  right: 0;

  font-family: "CircularStdBook";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;

  // @media (max-width: 640px) {
  //   position: static
  // }

}



.left-ellipse-send-action-plan {
  position:absolute;
  // top:508px !important;
  bottom: 0;
  left:0px !important;
  
}

.right-ellipse-send-action-plan {
  position:absolute;
  // top:434px !important;
  bottom: 0;
  left:30px !important;
  opacity: 0.3;

}


@media screen and (max-width:590px) {
  .summary-more-action{
      .form-input-wrapper{
        width: 76%;
        input{
          padding: 0 1rem;
        }
      }
      .send-button-top{
        width:25%
      }
  }
}
.submit-btn{
  &:focus, &:hover, &:active{
    outline: 0;
  }
}
.password-part{
  b{
    color: rgb(51, 202, 143);
  }
  i{
    cursor: pointer;
  }
}