@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

@font-face {
  font-family: 'Calibri';
  src: url('../assets/fonts/Calibri.eot');
  src: url('../assets/fonts/Calibri.eot?#iefix') format('embedded-opentype'),
       url('../assets/fonts/Calibri.svg#Calibri') format('svg'),
       url('../assets/fonts/Calibri.ttf') format('truetype'),
       url('../assets/fonts/Calibri.woff') format('woff'),
       url('../assets/fonts/Calibri.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

p {
  margin-bottom: 0.5vw;
}
@font-face {
  font-family: "TiemposHeadline";
  src: local("TiemposHeadline"),
    url("../assets/fonts/TiemposText-Regular.woff") format("woff");
}

@font-face {
  font-family: "CircularStdBook";
  font-style: normal;
  font-weight: 400;
  src: local(""), url("../assets/fonts/CircularStdBook.woff") format("woff");
}

@font-face {
  font-family: "CircularStdBook";
  font-style: normal;
  font-weight: 500;
  src: local(""), url("../assets/fonts/CircularStd-Medium.woff") format("woff");
}

@font-face {
  font-family: "CircularStdBook";
  font-style: normal;
  font-weight: 600;
  src: local(""), url("../assets/fonts/CircularStd-Bold.woff") format("woff");
}

body {
  font-size: 1em !important;
}

.subText {
  color: var(--black_700);
}
.homeHeadline {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: normal;
  color: var(--black);
  font-size: 2.5em !important;
  line-height: 50px;
  margin: 0;
}
.wwdtle {
  margin-top: 1vw;
  margin-bottom: 1vw;
}
.wwdtle b {
  font-size: 1.3vw;
  color: var(--black);
}
.dsblText {
  color: #50597C;
}
.fbnIntro-fbnIntro-home p:nth-child(2) {
  color: #50597C;
  margin-top: 30px;
  font-size: 1.1em;
  line-height: 30px;
  font-weight: 400;
  margin-bottom: 0;
  font-family: 'Poppins', sans-serif;
}


.fbnIntro-fbnIntro-home p:nth-child(1) span:nth-child(1) {
   color: var(--dark);
   font-weight: 450;
   max-width: 850px;
   display: inline-block;
}

.fbnAboutUs {
  padding: 90px 0;
}

.fbnAboutUs .homeButtons {
  padding: 15px 28px;
  font-size: 0.875em;
  line-height: 16px;
}

.fbnAboutUs p:nth-child(1) {
  /* font-size: 1.3vw; */
  font-weight: 500;
  font-size: 1em;
  line-height: 16px;
  color: var(--green);
  font-style: normal;
  text-transform: uppercase;
  font-family: 'Poppins', sans-serif;
  margin: 0 0 12px 0;
}
.fbnAboutUs p:nth-child(2) {
  font-weight: 600 !important;
  font-size: 2.25em !important;
  line-height: 48px;
  font-family: 'Poppins', sans-serif;
  color: var(--dark) !important;
  max-width: 680px;
  margin: 0 auto 14px;
  text-align: center;
}
.fbnAboutUs p:nth-child(3) {
  font-size: 1em;
  line-height: 26px;
  color: var(--solid_400);
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  max-width: 880px;
  margin: 0 auto 30px;
  text-align: center;
}

.how-we-work-block {
 background: #DFFFF3;
 padding: 90px 0;
 overflow: hidden;
}

.how-we-work-block h4 {
  font-weight: 500;
  font-size: 1em;
  line-height: 16px;
  color: var(--green);
  font-style: normal;
  text-transform: uppercase;
  font-family: 'Poppins', sans-serif;
  margin: 0 0 12px 0;
  text-align: center;
}

.how-we-work-block h2 {
  font-weight: 600 !important;
  font-size: 2.25em !important;
  line-height: 48px;
  font-family: 'Poppins', sans-serif;
  color: var(--dark) !important;
  margin: 0 auto 48px;
  text-align: center;
}

.work-two-block {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 50px;
  align-items: center;
}

.work-two-block .homeButtons {
  padding: 15px 28px;
  font-size: 0.875em;
  line-height: 16px;
}

.mental_helth_block {
  padding: 90px 0;
}

.mental_helth_block h2 {
  font-weight: 500;
  font-size: 1em;
  line-height: 16px;
  font-family: 'Poppins', sans-serif;
  margin: 0 0 18px 0;
  padding: 0;
  text-align: left;
  color: var(--green);
  text-transform: uppercase
}

.mental_helth_block {
  .section_3 {
    display: grid;
    grid-template-columns: 550px 1fr;
    grid-gap: 50px;
    align-items: center;
    .homeButtons {
      padding: 15px 28px;
      font-size: 0.875em;
      line-height: 16px;
      min-width: 218px;
    }
    p {
      font-weight: 400;
      font-size: 1.875em !important;
      line-height: 44px;
      font-family: 'Poppins', sans-serif;
      color: var(--dark);
      margin: 0 0 24px 0;
    }
    ul {
      margin: 0 0 30px 27px;
      padding: 0;
      li {
        list-style: disc;
        margin: 0;
        padding: 0;
        color: var(--solid_400);
        font-family: 'Poppins', sans-serif;
        font-size: 1em;
        line-height: 26px;
      }
    }
    img {
      width: 100%;
    }
  }
}
.fbnAboutUs_2 p span {
  color: var(--cyan_800);
}
.fbnAboutUs_3 {
  top: 78%;
  left: 12%;
  right: 55%;
}
.fbnAboutUs_3 p {
  font-weight: 400;
  font-family: 'Poppins', sans-serif;
  color: var(--dark);
  font-size: 1.375em;
  line-height: 34px;
  margin: 0 0 30px 0;
  text-align: center;
}

.fbnAboutUs_3 a{
  display: flex;
  justify-content: center;
}

#fbnVideo {
  width: 100%;
  border-radius: 24px;
  border: 0;
}
/* --------------------------------------------------------------------------------------------------------------------------------------------------------- */

// .section_3 {
//   position: absolute;
//   top: 12%;
//   left: 12%;
//   right: 12%;
// }
.section_3_1 p:nth-child(1) {
  font-size: 2.5vw;
}
.section_3_1 p:nth-child(2) {
  font-size: 1.3vw;
}
.section_3_2 img {
  width: 70%;
  height: auto;
}
.section_3_2 p:nth-child(1) {
  top: 13%;
  left: 41%;
  font-size: 0.9vw;
}
// .section_3_2 p {
//   position: absolute;
// }
// .section_3_2 p:nth-child(2) {
//   top: 78%;
//   left: 18%;
//   font-size: 0.9vw;
// }
// .section_3_2 p:nth-child(3) {
//   top: 78%;
//   left: 70%;
//   font-size: 0.9vw;
// }
/* --------------------------------------------------------------------------------------------------------------------------------------------------------- */
.features {
  background: var(--light_yellow);
  padding: 90px 0;

  .whatwedo-section {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 30px;
    align-items: center;
  }
}

.features p:nth-child(1) {
  font-size: 1.125em;
  color: var(--cyan_800);
}

.features .wwdtle {
  margin: 0;
  padding: 0;
}

.features .wow {
  padding: 24px 13px;
  transition: 1s;
  &:hover
  {
    background: #FFFFFF;
    box-shadow: 0px 36px 90px rgba(95, 100, 119, 0.12);
    border-radius: 12px;
    cursor: pointer;
    transition: 1s;
    min-height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}

.features .wow:hover img {
  display: none;
}

.features .wow:hover .wwdtle {
  display: none;
}

.features .wow:hover p {
  display: block;
}


.features h2 {
  font-weight: 600;
  font-size: 2.25em;
  line-height: 36px;
  color: var(--dark);
  font-family: 'Poppins', sans-serif;
  margin: 0 0 48px 0;
  padding: 0;
}

.features h4 {
  font-weight: 500;
  font-size: 1em;
  line-height: 16px;
  font-family: 'Poppins', sans-serif;
  margin: 0 0 12px 0;
  padding: 0;
  text-align: center;
  color: var(--green);
  text-transform: uppercase;
}

.features h3 {
  font-weight: 600;
  font-size: 1.5em;
  line-height: 24px;
  color: var(--dark);
  font-family: 'Poppins', sans-serif;
  margin: 0 0 14px 0;
  min-height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.features p {
  font-size: 1em;
  line-height: 26px;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  color: var(--solid_400);
  min-height: 85px;
  margin: 0;
  padding: 0;
  display: none;
}

/* --------------------------------------------------------------------------------------------------------------------------------------------------------- */

.how-it-work-block {
  padding: 90px 0 0;
}

.how-it-work-block h4 {
  font-weight: 500;
  font-size: 1em;
  line-height: 16px;
  font-family: 'Poppins', sans-serif;
  margin: 0 0 12px 0;
  padding: 0;
  text-align: center;
  color: var(--green);
  text-transform: uppercase;
}

.how-it-work-block h2 {
  font-weight: 600;
  font-size: 2.25em;
  line-height: 36px;
  color: var(--dark);
  font-family: 'Poppins', sans-serif;
  margin: 0 0 60px 0;
  padding: 0;
  text-align: center;
}


.how-it-work-block .homeButtons {
  margin: 0;
  padding: 15px 28px;
  font-size: 0.875em;
  line-height: 16px;
  min-width: 200px;
}

.ourAssessmentTitle {
  // position: absolute;
  // top: 9%;
  // left: 50%;
  // transform: translate(-50%, 0);
  color: var(--black);
}
.ourAssessmentTitle p {
  font-size: 3vw;
}
// .ourAssessmentBody {
//   position: absolute;
//   top: 8%;
//   padding: 13%;
// }

.ourAssessmentSections {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 110px;
  align-items: center;
}

.ourAssessmentSections span {
  font-size: 1vw;
  line-height: 1.8vw;
  color: var(--cyan);
  text-transform: uppercase;
}
.txt_1 {
  font-weight: 500;
  font-size: 1.75em;
  font-family: 'Poppins', sans-serif;
  margin: 0 0 18px 0;
  padding: 0;
  line-height: 40px;
  color: var(--dark);
}
.txt_2 {
  font-size: 1em;
  line-height: 22px;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  color: var(--solid_400);
  margin: 0 0 36px 0;
}

.four-text-block p:last-child {
  margin: 0;
}

/* testimonial section */
.textimonial-section .textc {
  color: var(--black_650);
}
/*company-setup-points */
.company-setup-points p {
  color: var(--black_550);
  font-size: 1.35vw;
}
/* --------------------------------------------------------------------------------------------------------------------------------------------------------- */
.testimonials-block {
  background: #DFFFF3;
  padding: 90px 10px;
}

.testimonials-block-two {
  display: grid;
  grid-template-columns: 440px 1fr;
  grid-gap: 70px;
  align-items: center;
}

.testimonials-block-two .img-block {
  border-radius: 30px !important;
  overflow: hidden;
}

.testimonials-block-two .img-block img {
  width: 100%;
}

.testimonials-block h4 {
  font-weight: 500;
  font-size: 1em;
  line-height: 16px;
  font-family: 'Poppins', sans-serif;
  margin: 0 0 12px 0;
  padding: 0;
  text-align: left;
  color: var(--green);
  text-transform: uppercase;
}

.testimonials-block h2 {
  font-weight: 600;
  font-size: 2.25em;
  line-height: 46px;
  color: var(--dark);
  font-family: 'Poppins', sans-serif;
  margin: 0 0 54px 0;
  padding: 0;
  text-align: left;
  max-width: 400px;
  display: inline-block;
  vertical-align: middle;
  text-transform: capitalize;
}

.testimonials-block .textc {
  font-size: 1em;
  line-height: 28px;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  color: #50597C;
  margin: 0;
  padding: 0;
}

.testimonials-block .homeText {
  color: var(--orange_500);
  font-size: 1.125em;
  line-height: normal;
  font-weight: 500;
  margin: 25px 0 0;
  padding: 0;
}

.testimonials-block .relative {
  position: relative;
  margin: 0;
  padding: 0;
}

.testimonials-block .fas.fa-quote-left {
  position: absolute;
  top: -24px;
  left: 0;
  color: rgba(51, 202, 143, 0.18);
  font-size: 5em;
}

.clientLogo {
  margin: 0;
  padding: 90px 0;
}

.clientLogo img {
  width: 100%;
}

.fbnClient {
  position: absolute;
  top: 0%;
}

.feeling-two-block {
  display: grid;
  grid-template-columns: 456px 1fr;
  grid-gap: 26px;
}

.feeling-two-block .left {
  background: url("../assets/images/imgpsh_fullsize_anim.png");
  background-repeat: no-repeat;
  background-position: -50px 0;
}

.feeling-two-block .fbnStatistics_list_Heading {
  font-size: 2.25em;
  line-height: 48px;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  color: var(--dark);
  margin: 0;
}

.feeling-two-block .fbnStatistics_list_Heading span {
  color: var(--green);
  display: block;
}

.feeling-block-wrapper .top-text {
  color: #979797;
  font-size: 1em;
  line-height: 26px;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  margin: 0 0 24px 0;
  padding: 0;
}

.feeling-block-wrapper h5 {
  color: var(--dark);
  font-size: 1em;
  line-height: 26px;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  margin: 0 0 20px 0;
  padding: 0;
}

.feeling-block-wrapper ul {
  margin: 0 0 0 25px;
  padding: 0;
}

.feeling-block-wrapper ul li {
  list-style: none;
  margin: 0;
  line-height: 40px;
  font-size: 1.125em;
  font-weight: 400;
  padding: 0;
  display: flex;
  align-items: center;
  grid-gap: 11px;
  color: var(--dark);
  font-family: 'Poppins', sans-serif;
}

.feeling-block-wrapper .homeButtons {
  margin: 36px 0 0;
  padding: 15px 28px;
  font-size: 0.875em;
  line-height: 16px;
}

.fa-chevron-left,
.fa-chevron-right {
  padding-top: 1.5vw;
  font-size: 4vw;
  margin-right: 1vw;
}
.fa-chevron-left {
  margin-left: 3vw;
}
.fbn_state {
  border-left: 7px solid var(--orange);
}
.fbnStatistics_list_sb {
  color: var(--dark);
  margin: 0;
  padding: 0;
}
.fbnStatistics_list_txt {
  color: var(--dark);
}
.fbn_state div {
  margin-left: 18px !important;
  display: flex;
  flex-direction: column;
}
.fbn_state b {
  color: var(--dark);
  font-size: 1.875em;
  line-height: 30px;
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
}
.fbn_state p {
  font-size: 1.125em;
  color: #9d9eb8;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  margin: 4px 0 0;
}
.fbn_state_1 {
  margin-top: 36px;
}

.map-block {
  position: relative;
}

.map-block-inner {
  position: absolute;
  top: 0;
  left: 0;
  padding-top: 48px;;
}
// .newsCarousal {
//   position: absolute;
//   top: 75%;
//   left: 50%;
//   transform: translate(-50%, 0);
// }
.newsCarousal {
  // margin: 90px 0 0;
  padding: 90px 0;
  background: var(--light_yellow);
}

.newsCarousal h2 {
  font-weight: 600;
  font-size: 2.25em;
  line-height: 36px;
  color: var(--dark);
  font-family: 'Poppins', sans-serif;
  margin: 0 0 48px 0;
  padding: 0;
  text-align: center;
}

.newsCarousal h4 {
  font-weight: 500;
  font-size: 1em;
  line-height: 16px;
  font-family: 'Poppins', sans-serif;
  margin: 0 0 12px 0;
  padding: 0;
  text-align: center;
  color: var(--green);
  text-transform: uppercase;
}

.newsCarousal .homeHeadline {
  font-weight: 600;
  font-size: 1.5em !important;
  line-height: 36px;
  font-family: 'Poppins', sans-serif;
  color: var(--black);
  margin: 0;
  padding: 0 48px 0 30px;
}

.newsCarousal .homeHeadline span {
  font-weight: 400;
  font-size: 16px !important;
  line-height: 16px;
  font-family: 'Poppins', sans-serif;
  color: #50597C;
  margin: 40px 0 0;
  display: block;
}
/* --------------------------------------------------------------------------------------------------------------------------------------------------------- */

.footer_section {
  // position: absolute;
  // top: 0%;
  background: url("../assets/images/footer.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  width: 100%;
  padding: 90px 0;
  position: relative;
  overflow: hidden;
}

.footer_section::before {
  background: rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
}

.footer_section h4 {
  font-weight: 500;
  font-size: 1em;
  line-height: 16px;
  font-family: 'Poppins', sans-serif;
  margin: 0 0 12px 0;
  padding: 0;
  text-align: center;
  color: var(--white);
  text-transform: uppercase;
  position: relative;
}

.footer_section h2 {
  font-weight: 600 !important;
  font-size: 2.25em !important;
  line-height: 48px;
  font-family: 'Poppins', sans-serif;
  color: var(--white) !important;
  margin: 0 auto 30px;
  text-align: center;
  position: relative;
}

.footer_section p {
  margin: 0 auto;
  padding: 0;
  font-size: 1em;
  line-height: 26px;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  color: var(--white_400);
  max-width: 878px;
  position: relative;
}

.footer_section input[type="email"] {
  box-shadow: none;
  height: auto;
  padding: 0;
  margin: 0;
  color: #A2A2AC;
  font-size: 1em;
  line-height: 30px;
  font-weight: 400;
  font-family: 'Poppins', sans-serif;
  
  ::-webkit-input-placeholder { /* Edge */
    color: #A2A2AC;
  }
  
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #A2A2AC;
  }
  
  ::placeholder {
    color: #A2A2AC;
  }
}


.footer_section button {
  margin: 0;
  padding: 15px 28px;
  font-size: 0.875em;
  line-height: 16px;
  min-width: 200px;
}

.email-icon {
  margin-left: 36px;
  margin-right: 12px;
}
/* --------------------------------------------------------------------------------------------------------------------------------------------------------- */

.smallText {
  position: absolute;
  top: 300px;
  font-size: 1.5vw;
  left: 60px;
}

/* .homePageColumn {
  width: 50%;
  float: left;
} */
.modal-body {
  padding: 0px;
  margin-top: 35px;
  margin-bottom: 15px;
}
.therapy-modal {
  margin-top: 20px;
}
.nav-link {
  cursor: pointer;
  color: #151852;
  font-weight: 400 !important;
  font-size: 17px;
  text-transform: capitalize;
}
.custom-modal {
  position: fixed;
  margin: auto;
  width: auto;
  top: 0px;
  right: 2%;
  bottom: 0px;
  border-radius: 15px;
}
.modal-content {
  border-radius: 15px;
  padding: 20px 30px;
  @media (max-width: 747px) {
    padding: 20px 16px 30px;
  }
}
.modal-contents {
  border-radius: 15px;
  padding: 0 !important;
}
/* .modal-backdrop {
  background-color: transparent;
} */
.homeLogo {
  position: absolute;
  margin-left: 4vw;
  margin-right: 4vw;
  top: 5%;
  /* width: 100%; */
  /* height: auto; */
}
.homeLogo img {
  height: auto;
  /* width: 100%; */
}

/* logo-size */

.homeNav li {
  font-family: "Poppins";
  font-size: 15px;
  line-height: 16px;
  /* padding-left: 4vw; */
  padding-left: 0;
  padding-right: 35px;
  color: var(--dark);
  font-weight: 400;
    cursor: pointer;
    color: #151852;
    font-weight: 400 !important;
    font-size: 17px;
  @media (max-width: 1440px) {
    & a .logo-size {
      // padding: 0 2em !important;
    }
  }
}

.homeNav li button {
  font-family: 'Poppins', sans-serif;
}

.homeNav li:last-child {
  padding-right: 0;
}

.home-banner-section {
  background: url("../assets/images/home_main_img.png");
  background-repeat: no-repeat;
  padding: 0;
  background-size: cover;
}

.fbnIntro-fbnIntro-home {
  position: relative;
  top: auto;
  -ms-transform: inherit;
  transform: inherit;
  max-width: 850px;
  padding: 120px 0 160px;
}

.homeNavBtn {
  background-color: var(--orange);
  border-radius: 5vw;
  color: var(--white_400);
  font-weight: 600;
  text-transform: uppercase;
  padding: 16px 28px;;
  font-size: 0.875em;
  margin-right: 0;
  line-height: 14px;
  &:hover {
    background-color: var(--black);
    color: var(--white_400);
  }
  @media (max-width: 1024px) {
    margin-right: 0;
  }
}
.homeButtons {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 1em;
  line-height: 16px;
  background-color: var(--orange);
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
  border-radius: 50px;
  color: var(--white);
  padding: 21px 27px;;
  margin-top: 48px;
  text-transform: uppercase;
  
  &:hover
    {
      background-color: var(--black);
      color: var(--white_400);
    }

}
// assessment navbar
.homeNav.assessmentNav{
  max-width: 1440px;
  width: 85% !important;
  @media (max-width: 640px) {
    width: auto !important;
  }
}
.homeNav.assessmentNav.startAssessment-conatiner{
  max-width: 1440px;
  width: 95% !important;
}
.homeNav.assessmentNav li button{
  font-family: 'Calibri' !important;
  font-weight: 500;
  color: var(--cyan_800);
  background-color: var(--white);
  text-transform: capitalize;
  font-size: 18px;
}
.homeNav.assessmentNav li button:hover{
  background-color:#33CA8F;
  color: var(--white);
}
// .homeNav.customHeader{
//   padding-left: 15px;
//   padding-right: 15px;
// }
.homeNav.customHeader{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.homeNav.customHeader .logo-part{
  // margin-right: 1rem;
}
.homeNav.customHeader .header-backdrop{
  display: none;
}
.homeNav.customHeader .header-backdrop.body-overlay{
  position: fixed;
    content: "";
    height: 100%;
    top: 0;
    right: 0;
    width: 100%;
    transition: 0.2s;
    background: rgba(0, 0, 0, 0.5) !important;
    backdrop-filter: blur(3px) !important;
    display: block;
}
.homeNav.customHeader .logout-part{
  display: flex;
}
.homeNav.customHeader .logout-part li.notification{
  padding-right: 18px;
}
.homeNav.customHeader .logout-part li.name{
  padding-right: 8px;
}
.homeNav.customHeader .logout-part .custom-header-dropdown{
  background: none;
  border: 0;
  width: auto;
  height: auto;
  position: relative;
}
.homeNav.customHeader .coins-part .coins-content{
  margin-left: 8px;
}
.homeNav.customHeader .coins-part .coins-content p{
  font-size: 10px;
  font-family: "Poppins";
}
.homeNav.customHeader .coins-part .coins-content strong{
  color: #33CA8F;
  font-size: 14px;
  line-height: 14px;
  text-transform: uppercase;
  font-family: "Poppins";
}
.homeNav.customHeader .logout-part .custom-header-dropdown .dropdown-menu.show{
  top: 27px !important;
  left: -110px !important;
  background: #FFFFFF;
  box-shadow: 8px 8px 60px rgba(111, 112, 138, 0.12);
  border-radius: 12px;
  transform: translate3d(-109px, 14px, 0px) !important;
}
.homeNav.customHeader .logout-part .custom-header-dropdown ul li{
  font-family: "Poppins";
}
.homeNav.customHeader .logout-part .custom-header-dropdown .dropdown-toggle{
  padding: 0;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  color: #151852;
  width: 108px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.homeNav.customHeader .logout-part .name-round{
  width: 48px;
  height: 48px;
  background: #33CA8F;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.homeNav.customHeader .logout-part .name-round P{
  font-family: "Poppins";
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 18px;
  text-align: center;
  color: #FFFFFF;
  margin: 0;
  text-transform: uppercase;
}
.homeNav.EducationNav{
  padding-bottom: 40px;
  max-width: 1440px;
  width: 95% !important;
  flex-basis: 95% !important;
  margin: 0 auto;
  padding-left: 0;
  padding-right: 0;
}
.homeNav.EducationNav{
  padding-top: 18px;
  padding-bottom: 18px;
}
.homeNav.EducationNav ul{
  margin-bottom: 0;
}
.homeNav.EducationNav li button{
  font-family: 'Calibri' !important;
  font-weight: 500;
  background: linear-gradient(180deg, #33ca8f, #0bb8c8);
  text-transform: capitalize;
  font-size: 18px;
}
.homeNav.EducationNav li button:hover{
  color: #151852;
}
/* @media only screen and (max-width: 1200px) { */
/* .homeNav li {
    color: black;
    font-size: 1.5vw;
    padding-right: 0vw;
  } */
/* .homeNav { */
/* border: 1px solid black; */
/* right: 2%; */
/* padding-right: 5vw; */
/* } */
/* .homeNavBtn { */
/* font-size: 1.5vw; */
/* padding: 10px 20px; */
/* font-size: 10px; */
/* } */
/* .logo {
    width: 150px;
  } */
/* } */

/* @media only screen and (max-width: 700px) {
  .logo {
    width: 150px;
  }
}
@media only screen and (max-width: 425px) {
  .logo {
    width: 100px;
  }
} */
.main-container {
  max-width: 1440px;
  width: 85%;
  margin: auto;
}
.main-container.start-assessment-container{
  max-width: 1440px;
  width: 95%;
}
.menu-wrapper {
  padding-top: 45px;
  padding-bottom: 45px;
}
.menu-wrapper .navbar {
  padding: 0;
}
.assestment li {
  font-family: 'Poppins', sans-serif;
  font-size: 1.063em;
  /*  padding-left:0.6vw;
  padding-right: 2vw;*/
  color: black;
  font-weight: 400;
  padding: 0px 2vw 0px 0vw !important; 
}
.assestment li:last-child {
  padding-right: 0px !important;
}
.assestmentNavBtn {
  color: black;
  border: 0;
  margin: 0;
  padding: 0;
  background: none;
  outline: none;
  color: var(--dark);
  display: block;
  font-size: 1.063em;
}
.signinBtn {
  background-color: var(--white);
  border-radius: 5vw;
  color: var(--cyan_800);
  padding: 0.9vw 14px;
  font-size: 1.063em;
}
button.btn.assestmentNavBtn:hover {
  outline: none;
  color: #0056b3;
}
button.btn.assestmentNavBtn:focus {
  outline: none;
}
.pr-40 {
  padding-right: 2vw;
}
/* assestment end */
.ourAssessmentSections {
  margin-bottom: 90px;
}
/* assessment-hitory start */
/* .assessment-hitory-leftsidebar {
} */
.assessment-hitory-leftsidebar .modal-content {
  background: transparent;
}
.modal-header .close {
  outline: none;
  background: transparent;
  opacity: 1;
  color: #33ca8f;
  padding: 7px;
  margin: 0;
  cursor: pointer;
}
.modal-header .close:focus {
  outline: none;
  background: transparent;
}
.close-button {
  background: #ffffff;
  border-radius: 50%;
}
.ahl-side {
  color: #505050;
  font-size: 1em;
  line-height: 20px;
}
.ahl-side li {
  box-shadow: "0 1px 4px 0 rgb(32 32 36 / 28%)";
}
.bg-lihead {
  background: #e3e3e3;
}
.ahl-side .list-group-item {
  border-radius: 4px;
}
.list-group-assessment-item {
  position: relative;
  display: block;
  /* padding: 0.75rem 1.25rem; */
  background: #ffffff;
  color: #505050;
  /* border: 2px solid #eaeaea !important; */
}
.list-group-risk-items {
  background: #ffffff;
  border: 2px solid #eaeaea !important;
  font-size: 1em;
  line-height: 20px;
}
.list-group-assessment-header {
  padding: 0.75rem 1.25rem;
  border: 2px solid #eaeaea !important;
  margin: auto;
}
/* .list-group-assessment-item:first-child {
} */
.asmntHstrclps,
.asmntHstrclps {
  border-radius: 4px !important;
}
/* assessment-hitory end */
/* SummaryMainCard start */
.summary i {
  color: rgb(31, 144, 242);
}
.summary p {
  margin-bottom: 1em;
  font-size: 1.4vw;
}
.socialMediaIcons i {
  color: #589ce1;
}

.SummaryMainCard small {
  font-size: 0.8rem;
  opacity: 0.8;
}
.mr-10 {
  margin-right: 10px;
}
.mr-6 {
  margin-right: 6px;
}
.mt-10 {
  margin-top: 10px;
}
.card-header {
  padding: 0.6rem 1.25rem;
}
/* SummaryMainCard end */
.whatWeDoRow img {
  height: 110px;
  margin-bottom: 30px;
}

.appDownloadImg {
  width: 10vw;
}
.contact {
  top: 93%;
  position: absolute;
  font-size: 1vw;
}
.mail,
.fa-mobile-android-alt {
  color: var(--orange);
}
.footerHeading {
  padding-top: 0;
}

.card-body-footer {
  /* height: 100px; */
  /* padding: 10px; */
  padding: 12px;
  border-radius: 50px;
  background-color: var(--white);
  box-shadow: 0px 24px 72px rgba(80, 89, 124, 0.12);
  border-radius: 50px;
  margin: 60px auto 0;
  max-width: 704px;
  position: relative;
}
.footerActions {
  /* margin-top: 15%; */
  /* padding: 1%; */
  /* margin-top: 15%; */
  // padding: 0% 15%;
  // top: 70%;
  // width: 100%;
  /* margin-right: 15%; */
  /* margin-left: 15%; */
}
.footerActions h1 {
  font-size: 1.5vw;
}
.footerActions h1 span {
  color: var(--cyan_800);
}
.footerActions p span {
  color: var(--orange);
}
.footer_hr {
  top: 85%;
  width: 100%;
}
.footer_hr hr {
  margin-right: 15%;
  margin-left: 15%;
}

.footerActions p {
  font-size: 1vw;
}
.footerActions ul li {
  font-size: 1vw;
  list-style-type: none;
  padding: 10%;
}
.flickity-enabled {
  position: relative;
}
.flickity-enabled:focus {
  outline: 0;
}
.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%;
}
.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%;
}
.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}
.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}
.flickity-button {
  position: absolute;
  background: hsla(0, 0%, 100%, 0.75);
  border: none;
  color: #333;
}
.flickity-button:hover {
  background: var(--white);
  cursor: pointer;
}
.flickity-button:focus {
  outline: 0;
  box-shadow: 0 0 0 5px #19f;
}
.flickity-button:active {
  opacity: 0.6;
}
.flickity-button:disabled {
  opacity: 0.3;
  cursor: auto;
  pointer-events: none;
}
.flickity-button-icon {
  fill: currentColor;
}
.flickity-prev-next-button {
  top: 50%;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  transform: translateY(-50%);
}
.flickity-prev-next-button.previous {
  left: 10px;
}
.flickity-prev-next-button.next {
  right: 10px;
}
.flickity-rtl .flickity-prev-next-button.previous {
  left: auto;
  right: 10px;
}
.flickity-rtl .flickity-prev-next-button.next {
  right: auto;
  left: 10px;
}
.flickity-prev-next-button .flickity-button-icon {
  position: absolute;
  left: 20%;
  top: 20%;
  width: 60%;
  height: 60%;
}
.flickity-page-dots {
  position: absolute;
  width: 100%;
  bottom: -25px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1;
}
.flickity-rtl .flickity-page-dots {
  direction: rtl;
}
.flickity-page-dots .dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 8px;
  background: #333;
  border-radius: 50%;
  opacity: 0.25;
  cursor: pointer;
}
.flickity-page-dots .dot.is-selected {
  opacity: 1;
}
/* custom scroll bar */
.assessmentQuestionsCard {
  overflow-y: scroll;
}
.force-overflow {
  min-height: auto;
}
#custom-scrollbar::-webkit-scrollbar-track {
  /*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);*/
  border-radius: 10px;
  background-color: #f5f5f5;
}
#custom-scrollbar::-webkit-scrollbar {
  width: 10px;
  background-color: var(--white);
  margin-right: 10px;
  cursor: pointer;
}
#custom-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  /*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);*/
  background-color: #eff0ed;
  cursor: pointer;
}

/* scroll bar end */
/* model popup */

.modal-dialog {
  max-width: 900px;
}
.outcome-popup .bg-light {
  background: #ebebeb;
}
.outcome-popup .bg-light .active {
  background: #dafff1;
}
.outcome-popup h5 {
  font-family: 'Poppins', sans-serif;
  color: #151852;
  font-size: 1.5vw;
  font-weight: 600;
}
.outcome-popup p {
  font-size: 0.9vw;
  color: #151852;
}

@media only screen and (max-width: 1200px) {
  .homeNav li {
    color: black;
    padding-right: 0vw;
  }
  .homeNavBtn {
    /* padding: 10px 20px; */
    /* font-size: 10px; */
  }
  /* .logo {
    width: 150px;  
  } */
   .homeNav li {
    padding-left: 1.5vw;
  }
}
@media only screen and (max-width: 991px) {
  .navbar-nav {
    border-radius: 8px;
    width: 220;
    float: right;
    padding: 15px;
    padding-top: 3px;
    background-color: var(--white);
    box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0.1) !important;
    margin-top: 15px;
  }
  .navbar-nav:before {
    content: "";
    width: 0;
    height: 0;
    border-bottom: 10px solid #fff;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    position: relative;
    top: -12px;
    left: 87%;
  }
  .navbar-nav li {
    margin: 0 !important;
    text-align: left;
    padding: 5px 0 !important;
    font-family: 'Calibri';
    font-size: 1.125em;
    /* padding-left: 4vw; */
    color: var(--dark);
    font-weight: 400;
  }
  .navbar-toggler-icon {
    background-image: url("../assets/images/humbmenu.svg") !important;
  }
  nav.navbar.navbar-expand-lg.pt-4.pr-5.mr-3.hamb-icon {
    padding-right: 1rem !important;
  }
  .hamburger-menu {
    position: absolute;
    right: 0px;
    top: 82%;
    z-index: 999;
  }
  .hamburger-menu.collapse:not(.show) {
    display: none;
  }
  .hamburger-menu.navbar-collapse {
    display: block;
  }
  .menu-wrapper {
    padding-top: 20px;
    padding-bottom: 10px;
  }
  .homeNav.customHeader .logout-part {
    display: none;
  }
}
@media only screen and (max-width: 700px) {
  .logo {
    width: 120px;
  }
  .inner-logo {
    width: 100%;
  }
  .inner-logo {
    width: 100%;
  }
  .d-xs-hidden {
    display: none;
  }
  .menu-wrapper {
    padding-top: 20px;
    padding-bottom: 10px;
  }
  .assessmentCard {
    margin-top: 10px;
  }
}
@media only screen and (max-width: 425px) {
  .logo {
    width: 120px;
  }
  .inner-logo {
    width: 100%;
  }
  
  .wwdtle h3 {
    letter-spacing: 0px;
    transition: all 1s;
  }
  .homeNav li {
    padding-left: 2.5vw;
  }
  button.close.pt-2 {
    background-color: var(--white);
    border-radius: 100%;
    padding: 10px;
    position: inherit;
    width: 28px;
    height: 28px;
    right: 10px;
    top: 26%;
    opacity: 0.5;
  }

  .covid-19_alert button span {
    color: #1a3b41 !important;
    top: -6px;
    position: relative;
    left: -2px;
  }
  .covid-19_alert p {
    line-height: 1.3;
  }
  .hamb-icon {
    padding-right: 0 !important;
    margin-right: 0px !important;
  }
  .assessmentCard {
    margin-top: 20px;
  }
  .answeredCard {
    border-radius: 8px !important;
  }
  .titleAsmnt {
    line-height: 1.2;
    font-size: 1.625em;
  }
  .leftcard-view-m {
    box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0.1) !important;
    margin-left: -4px;
    margin-right: -19px;
    margin-top: -4px;
    width: 103%;
    -webkit-border-top-left-radius: 15px;
    -webkit-border-top-right-radius: 15px;
    -moz-border-radius-topleft: 15px;
    -moz-border-radius-topright: 15px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }
  .mobile-view .mt-5.m-3 {
    margin-top: 5vw !important;
  }

  .assestment li {
    font-size: medium !important;
    padding: 0px 2vw 0px 0.6vw !important;
  }
  .assestmentNavBtn {
    font-size: medium !important;
  }
  .tabShadow {
    top: 0;
  }
}

.lngBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1rem;
  height: 1rem;
  margin: 0.5rem;
  /* padding: 1rem; */
  /* border: 0.125rem solid rgba(12, 184, 201, 0.5); */
  padding: 1.3rem;
  border: 0.45rem solid rgba(12, 184, 201, 0.5);
  border-radius: 50%;
  background-color: #e1f4f4;
}

.lngBtn:focus,
.lngBtn:active {
  outline: none;
}

.downButtons {
  display: flex;
  flex-direction: row;
  position: fixed;
  bottom: 10px;
  left: 20px;
  z-index: 99999999;
}

@media only screen and (max-width: 800px) {
  .newsCard {
    width: 60% !important;
    margin-left: 1% !important;
    margin-right: 1% !important;
}
}

.fbnAboutUs_2 {
  @media screen
    and (max-device-width: 640px){
      padding-top: 25px;
  }

  @media screen
    and (min-device-width: 640px)
    and (max-device-width: 1024px){
    }
}

@media (max-width: 1024px) {
  .clientLogo img {
    width: 100%;
  }
  .PAP-headerSmalldevices{
    width: 95% !important;
  }
  .newsCarousal .homeHeadline {
    font-size: 1.25em !important;
    line-height: 32px;
  }

  .ourAssessmentSections {
    grid-gap: 20px;
  }

  .homeNav li.header-logo {
    padding-left: 0;
  }

  .fbnIntro-fbnIntro-home {
    max-width: 100%;
  }

  .mental_helth_block .section_3 p {
    font-size: 1.563em !important;
    line-height: 35px;
  }

  .fbnAboutUs_3 p {
    font-size: 1.125em;
    line-height: 30px;
    text-align: center;
  }

  .footer-block {
    display: grid;
    grid-template-columns: 280px 280px 1fr 1fr;
  }
}

@media (max-width: 992px) {
  .features .whatwedo-section {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;
    align-items: center;
  }

  .ourAssessmentSections {
    display: grid !important;
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;
  }

  .homeHeadline {
    font-size: 40px !important;
    line-height: 55px;
  }

  .ourAssessmentSections img {
    width: 100%;
  }

  .txt_1 {
    font-size: 1.25em;
    line-height: 30px;
  }

  .testimonials-block-two {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
    align-items: center;
    justify-content: center;
  }

  .CarouselWrapper .slick-slide img {
    display: block;
    margin: 0 auto;
  }

  .left-rounded-arrow_right {
    right: 0;
    left: auto !important;
  }

  .mental_helth_block .section_3 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;
  }

  .mental_helth_block .section_3 p {
    font-size: 1.25em !important;
    line-height: 30px;
  }

  .features h3 {
    font-size: 1.25em;;
  }

  .testimonials-block h2 {
    max-width: 100%;
    margin: 0 0 30px 0;
  }

  .feeling-two-block {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
  }

  .feeling-block-wrapper ul {
    margin: 0;
    padding: 0;
  }

  .feeling-block-wrapper ul li {
    margin: 0 0 10px 0;
    line-height: normal;
    font-size: 0.938em;
    font-weight: 400;
  }

  .newsCarousal .homeHeadline {
    font-size: 0.938em !important;
    line-height: normal;
  }
  
  .newsCarousal .homeHeadline span {
    font-size: 0.875em !important;
    line-height: 20px;
    margin: 20px 0 0;
  }

  .work-two-block {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 40px;
    align-items: center;
  }

  #fbnVideo {
    height: 300px;
  }

  footer {
    padding: 40px 0 40px !important;
  }

  .footer-block {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
  }

  .copyright {
    margin: 28px 0 0;
  }

  .homeNavBtn {
    padding: 16px 15px;
  }
}

.navbar-toggler,
.only-show-in-mobile  {
  display: none;
}

.homeNav {
  position: relative;
  z-index: 99;
  margin: 0 auto;
  padding-top: 40px;
  max-width: 1160px;
}

@media (max-width: 992px) {
  
  .navbar-toggler {
    display: block !important;
  }
  .navbar-toggler.active span{
    display: none;
  }
  .only-show-in-mobile.sidebar-show{
    transform: translateX(0);
  }
  .only-show-in-mobile .nav-header-part{
    margin-bottom: 40px;
  }
  .only-show-in-mobile  {
    display: none;
    position: fixed;
    top: 0px;
    background: #fff;
    width: 320px;
    left: 0;
    height: 100vh;
    padding: 24px 18px 24px 24px;
    transition: transform .3s;
    transform: translateX(-100%);
    box-shadow: 8px 8px 60px rgba(111, 112, 138, 0.12);
  }

  .only-show-in-mobile .container {
    max-width: 100%;
  }

  .homeNav li {
    margin: 0;
    padding: 0;
  }

  .nav-link.active,
  .nav-link {
    color: #fff !important;
    font-size: 1em;
    font-weight: 400;
  }

  .btn.nv-mobile-btn {
    color: #fff;
    font-size: 1em;
    font-weight: 400;
    padding: 15px;
    background: #26b77e;
    border-radius: 5px;
    width: 100%;
    margin: 0;
    text-align: left;
  }

  .home-banner-section {
    padding: 0;
  }

  .navbar-toggler {
    width: 40px;
    height: 28px;
    position: relative;
    margin: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
  }

  .navbar-toggler span {
    display: block;
    position: absolute;
    height: 5px;
    width: 100%;
    background: #33ca8f;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
  }

  // .navbar-toggler.active span:nth-child(1) {
  //   top: 9px;
  //   -webkit-transform: rotate(135deg);
  //   -moz-transform: rotate(135deg);
  //   -o-transform: rotate(135deg);
  //   transform: rotate(135deg);
  // }

  // .navbar-toggler.active span:nth-child(2) {
  //   opacity: 0;
  //   left: -60px;
  // }

  // .navbar-toggler.active span:nth-child(3) {
  //   top: 9px;
  //   -webkit-transform: rotate(-135deg);
  //   -moz-transform: rotate(-135deg);
  //   -o-transform: rotate(-135deg);
  //   transform: rotate(-135deg);
  // }

  .only-show-in-mobile ul li {
    display: block !important;
  }

  .only-show-in-mobile ul li[role=button] {
    padding: 15px;
    display: block;
    background: #26b77e;
    border-radius: 5px;
    margin: 5px 0;
    color: #fff;
    font-size: 1em;
    font-weight: 400;
  }
  .only-show-in-mobile ul li:hover,.only-show-in-mobile ul li:hover a,.only-show-in-mobile ul li:hover a:hover{
    color: #33CA8F !important;
  }
  .only-show-in-mobile ul li a,.only-show-in-mobile ul li[role=button] {
    padding: 15px 0;
    display: block;
    font-size: 18px;
    line-height: 24px;
    color: #151852 !important;
    font-family: "Poppins";
    background: none;
    margin: 0;
  }

  .nav-link.active {
    background: #151852;
  }

  .navbar-toggler span:nth-child(1) {
    top: 0px;
  }

  .navbar-toggler span:nth-child(2) {
    top: 11px;
  }

  .navbar-toggler span:nth-child(3) {
    top: 22px;
  }

  @media screen
  and (max-device-width: 640px){
    .d-sm-none-custom {
      opacity: 0;
    }
  }

  .homeNav {
    padding: 20px 0;
    padding-left: 1.5vw;
    padding-right: 1.5vw;
    align-items: center;
    // background: #fff;
    position: relative;
    top: 0 !important;
    z-index: 99;
    left: 0;
    animation-name: inherit !important;
    // box-shadow: 0px 5px 5px -5px #dddfe3;
  }

  .homeNav li.header-logo {
    display: block !important;
  }

  .logo {
    width: auto !important;
  }

  .homeNav li {
    display: none !important;
  }

  .fbnIntro-fbnIntro-home {
    position: relative;
    top: auto;
    transform: inherit;
    max-width: 100%;
    padding: 30px 10px;
  }

  .homeHeadline {
    font-size: 1.875em !important;
    line-height: 40px;
  }

  .fbnIntro-fbnIntro-home p:nth-child(2) {
    margin: 20px 0 0;
    padding: 0;
    font-size: 1em;
    line-height: 25px;;
  }

  .features,
  .how-we-work-block,
  .mental_helth_block,
  .how-it-work-block,
  .testimonials-block,
  .clientLogo,
  .footer_section,
  .fbnAboutUs {
    padding: 40px 10px;
  }

  .how-we-work-block h2 {
    margin: 0;
  }

  .fbnAboutUs_3 p {
    margin: 0 0 10px 0;
    text-align: center;
  }

  .work-two-block {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 40px;
    align-items: center;
  }

  #fbnVideo {
    height: 200px;
  }

  .features h2,
  .how-we-work-block h2,
  .how-it-work-block h2,
  .testimonials-block h2,
  .newsCarousal h2,
  .footer_section h2,
  .fbnAboutUs p:nth-child(2) {
    font-size: 1.625em !important;
    line-height: 32px;
    text-align: center;
  }

  .testimonials-block h4 {
    text-align: center;
  }

  .how-it-work-block h2,
  .footer_section h2 {
    margin: 0 0 20px 0;
  }
  
  .how-it-work-block .homeButtons {
    margin: 0 auto;
    display: block;
  }

  .testimonials-block .relative {
    text-align: center;
  }

  .testimonials-block .textc {
    text-align: center;
    line-height: 25px;
  }

  .ourAssessmentSections {
    margin-bottom: 40px;
  }

  .features .wow:hover .wwdtle {
    display: block;
  }
  
  .features .wow:hover img {
    display: block;
    margin: 0 auto 20px;
  }

  .features p {
    min-height: auto;
    display: block;
  }

  .whatWeDoRow img {
    margin: 0 0 20px 0;
  }

  .features h3 {
    min-height: inherit;
  }

  .features .wow {
    padding: 24px 13px;
  }

  .features p {
    font-size: 0.875em;
    line-height: 24px;
  }

  .CarouselWrapper .slick-slide img {
    display: block;
    width: 100%;
  }

  .left-rounded-arrow_right,
  .left-rounded-arrow_left {
    display: none !important;
  }

  .testimonials-block .homeText {
    font-size: 0.875em;
    line-height: 20px;
    font-weight: 500;
    margin: 15px 0 0;
    text-align: center;
  }

  .feeling-two-block .fbnStatistics_list_Heading {
    font-size: 1.875em;
    line-height: 40px;
  }

  .feeling-block-wrapper {
    padding: 0 10px;
  }

  .feeling-block-wrapper ul li {
    font-size: 1em;
  }

  .feeling-two-block {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }

  .newsCarousal {
    margin: 40px 0 0;
    padding: 40px 10px;
  }

  .newsCarousal .homeHeadline {
    padding: 20px;
  }

  .footer_section p {
    padding: 0 !important;
    font-size: 0.875em;
    line-height: 22px;
  }

  .email-icon {
    margin-left: 10px;
    margin-right: 10px;
  }

  .footer_section button {
    margin: 0;
    padding: 15px 10px;
    font-size: 0.75em;
    line-height: 14px;
    min-width: inherit;
  }

  .footer_section input[type="email"] {
    font-size: 0.875em;
  }

  footer {
    padding: 30px 10px 30px !important;
  }

  .footer-block {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }
  
  .footer-block h2 {
    margin: 0 0 15px 0;
  }

  .ourAssessmentSections img {
    width: 80%;
    margin: 0 auto;
    display: block;
    float: none !important;
  }

  .mental_helth_block .section_3 img {
    width: 80%;
    margin: 0 auto;
    display: block;
    float: none !important;
  }

  .work-two-block .homeButtons {
    margin: 0 auto;
    display: block;
  }

  .ourAssessmentSections.four-text-block {
    margin: 0;
  }

  .map-block-inner {
    position: relative;
    top: 0;
    left: 0;
    padding-top: 30px;
  }

  .feeling-two-block .left {
    background: none;
  }
}
.nav-link:hover, .nav-item button.btn:hover {
  color: #33CA8F;
}
.nav-item:hover {
  color: #33CA8F;
}
#therapyModal{
  position: fixed !important;
    left: 50% !important;
    // top: 50% !important;
    transform: translate(-50%, 0)!important;
}

@media (max-width: 767px) {
  .ourAssessmentSections {
    display: grid !important;
    grid-template-columns: 1fr;
    grid-gap: 30px;
  }

  .txt_1 {
    font-size: 1.125em;
    line-height: 25px;
    text-align: center;
  }

  .txt_2 {
    margin: 0 0 20px 0;
    text-align: center;
  }

  .two-text-block div:first-child {
    order: 2;
  }

  .two-text-block div:last-child {
    order: 1;
  }

  .four-text-block div:first-child {
    order: 2;
  }

  .four-text-block div:last-child {
    order: 1;
  }

  .features .whatwedo-section {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 15px;
    align-items: center;
  }

  .mental_helth_block .section_3 {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }

  .icon-cards {
    height: 400px !important;
  }

  .newsCarousalImageDiv img {
    width: 100%;
  }

  .icon-cards__item {
    display: block !important;
  }


}

@media screen  and (max-width:460px){
  .f-logo{
    width: 200px!important;
    margin-left: 8px !important;
    margin-top: 0 !important;
  }
  .m-logo{
    width:120px !important;
  }
} 
@media screen  and (max-width:420px){
  .f-logo{
    width: 180px!important;
  }
}
@media screen  and (max-width:375px){
  .f-logo{
    width: 180px!important;
    margin-left: 0x !important;
  }
  .m-logo{
    width:80px !important;
  }
  .navbar-toggler span{
    width:80%;
  }
  .titleAsmnt{
    font-size: 1.5em;
  }
} 
@media screen  and (max-width:325px){
  .f-logo{
    width: 158px!important;
    margin-left: 0x !important;
  }
  .m-logo{
    width:80px !important;
  }
  .navbar-toggler span{
    width:80%;
  }
} 

.custom-sidebarHeader{
  display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 1.5rem;
    .logo-part{
      img{
        margin-left: 0 !important;
        width:160px !important
      }
    }
}

.modal-btn{
  padding: 10px 18px;
  font-size: 1em;
  letter-spacing: 0.03em;
}

.title-center{
  text-align: center;
}

@media screen and (max-width:1440px) {
  .PAP-headerSmalldevices{
    width: 98% !important;
  }
}



.home-page-list{
  list-style-type: none;
}
.home-page-list li{
  font-weight: 400;
    font-family: 'Poppins', sans-serif;
    color: var(--dark);
    font-size: 18px;
    line-height: 30px;
    margin-bottom: 6px;
    position: relative;
    padding-left: 30px;
}
.home-page-list li::before{
  position: absolute;
  content: "";
  background-image: url('../assets/images/leafs.svg');
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  left: 0;
  top: 6px;
}