.change-1 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.adjust-1 {
  /* margin-top: 0.25rem !important; */
}

.list-items {
  margin-top: 15px;
  list-style-type: none;
  text-align: left;
}

footer {
  width: 100%;
  background: #DFFFF3;
  position: relative;
  padding: 40px 0 20px !important;
}
.footer .container.assessment-conatiner{
  max-width: 1440px;
  width: 95% !important;
}
.footer-bl .container.summary-container,.footer-bl .container.education-container{
  width: 95% !important;
  max-width: 98em;
}
.footer-bl .container.pap-container{
  width: 88% !important;
  max-width: 1440px;
}
.footer-block {
  display: grid;
  grid-template-columns: 360px 300px 1fr 1fr;
}

.footer-block h2 {
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  color: var(--green);
  font-family: 'Poppins', sans-serif;
  margin: 0 0 25px 0;
  padding: 0;
}

.footer-block ul {
  margin: 0;
  padding: 0;
}

.footer-block ul li {
  margin: 0 0 14px 0;
  padding: 0;
  list-style: none;
  font-size: 14px;
  line-height: 21px;
  color: var(--dark);
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
}

.footer-block ul li:last-child {
  margin: 0;
}

.social-icon {
  display: grid;
  grid-template-columns: repeat(4, 24px);
  grid-gap: 9px;
}

.footer-block ul.social-icon li {
  margin: 0;
  padding: 0;
}

.social-icon li a {
  text-indent: -9999px;
  display: inline-block;
  vertical-align: middle;
}

.social-icon .facebook a {
  background-image: url("../assets/images/social_icon_img.png");
  background-repeat: no-repeat;
  background-position: 0 0;
  width: 24px;
  height: 24px;
}

.social-icon .facebook a:hover {
  background-position: 0 -26px;
}

.social-icon .twitter a {
  background-image: url("../assets/images/social_icon_img.png");
  background-repeat: no-repeat;
  background-position: -33px 0;
  width: 24px;
  height: 24px;
}

.social-icon .twitter a:hover {
  background-position: -33px -26px;
}

.social-icon .linkedin a {
  background-image: url("../assets/images/social_icon_img.png");
  background-repeat: no-repeat;
  background-position: -66px 0;
  width: 24px;
  height: 24px;
}

.social-icon .linkedin a:hover {
  background-position: -66px -26px;
}

.social-icon .instagram a {
  background-image: url("../assets/images/social_icon_img.png");
  background-repeat: no-repeat;
  background-position: -99px 0;
  width: 24px;
  height: 24px;
}

.social-icon .instagram a:hover {
  background-position: -99px -26px;
}

.footer-block ul li.ftr-logo {
  margin: 0 0 36px 0;
  padding: 0;
}

.copyright {
  font-size: 12px;
  line-height: 18px;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  margin: 95px 0 0;
  padding: 0;
  color: var(--dark);
}

.footer-block ul.list-items.address-dtl li {
  display: grid;
  grid-template-columns: 21px 1fr;
  grid-gap: 6px;
  align-items: center;
}
@media screen and (max-width:1024px) {
  .footer-bl .container.pap-container{
    width: 95% !important;
  }
}

@media (max-width: 767px) {
  .copyright {
    margin: 20px 0 0;
  }
}

@media screen and (max-width:1440px) {
  .footer-bl .container.pap-container{
    width: 98% !important;
  }
}