.circle-margin {
  margin-left: 13px;
  margin-right: 4px;
  font-size: 0.76em;
}

.medication-table {
  h3 {
    font-size: 0.9em;
  }

  p {
    font-size: 0.9em;
  }
}

.h2-pap {
  font-weight: 600;
  font-size: 1.6em;
  color: #f3763e;
}

@media print {
  .no-print {
    display: none;
  }
}

.goal-content-wrapper {
  clear: both;
  padding: 0 30px 30px;

  .goal-content-inner {
    // border-top: 2px solid #EEEEEE;
    padding: 36px 0 0;

    h2 {
      font-size: 30px;
      line-height: 30px;
      color: #151852;
      font-family: "Poppins", sans-serif;
      font-weight: 700;
      margin: 0 0 24px 0;
    }

    p {
      font-size: 14px;
      line-height: 22px;
      font-weight: 400;
      font-family: "Poppins", sans-serif;
      color: #505050;
      margin: 0 0 24px 0;

      strong {
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;
        color: #151852;
      }
    }
  }
}

.full-divide-block {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
  align-items: flex-start;
}

.full {
  display: grid;
  grid-template-columns: 290px 1fr;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 24px 0;
}

.full .right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.goal-content-wrapper {
  .full {
    .right {
      .add-btn {
        width: 38%;
      }
    }
  }
}

.dropdown-clear-part {
  position: relative;

  .clear-btn {
    position: absolute;
    top: 13px;
    right: 29px;
  }
}

.dropdown.symptom {
  background: #ffffff;
  border: 1px solid #33ca8f;
  box-sizing: border-box;
  border-radius: 6px;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #33ca8f !important;
  transition: 0.3s;
  font-family: "Poppins", sans-serif;
  padding: 0;
  text-align: center;
  // width: 176px;
  height: 48px;
  line-height: 45px;

  .dropdown-toggle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 12px !important;
    span{
      display: inline-block;
      width: 230px;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      text-align: left;
    }
    &:hover {
      color: #33ca8f !important;
    }
  }

  .dropdown-menu.show {
    left: 0 !important;
    right: 0 !important;
    background: #ffffff;
    // box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.12);
    border: 0;
    border-radius: 0;
    margin: 3px 0 0;
    width: 220px;
    padding: 12px;
    border: 1px solid rgb(221, 223, 227);
    border-radius: 4px;
    top: 5px !important;
    width: 100%;
  }
}

.dropdown.symptom ul {
  display: block !important;
  margin: 0 !important;
  padding: 0 !important;
  margin-top: 8px !important;

  li {
    margin: 0;
    padding: 12px 0;
    list-style: none;
    font-size: 14px;
    line-height: 14px;
    display: flex;
    align-items: center;
    letter-spacing: 0.01em;
    color: #151852;
    font-weight: 400;
    justify-content: space-between;
    cursor: pointer;
    text-transform: capitalize;

    span {
      font-weight: 600;
      font-size: 12px;
    }

    &:hover {
      background: #e7f9f7;
      margin: 0 -12px;
      padding: 12px 12px;
    }
  }
}

.dropdown.symptom ul li.add-btn {
  padding: 0;
  margin: 12px 0 0;
}

.dropdown.symptom ul li.add-btn a {
  width: 56px;
  height: 24px;
}

.dropdown.symptom ul li.add-btn input {
  padding: 0 90px 0 12px;
  font-size: 12px;
  line-height: 14px;
  height: 36px;
}

.dropdown.symptom ul li.active {
  background: #e7f9f7;
  margin: 0 -12px;
  padding: 12px 12px;
  position: relative;
}

.dropdown.symptom ul li.active::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 4px;
  background: #33ca8f;
  height: 100%;
  content: "";
}

.dropdown {
  background: linear-gradient(180deg, #33ca8f, #0bb8c8);
  color: #fff !important;
  transition: 0.3s;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  padding: 0;
  border-radius: 6px;
  border: 1px solid #4cf7b7;
  text-align: center;
  border: none;
}

.dropdown a:hover {
  color: #fff !important;
}

.dropdown .dropdown-toggle {
  display: inline-block;
  padding: 13px 22px;
  width: 100%;
}

.dropdown i {
  margin-left: 10px;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu a:hover {
  color: #33ca8f !important;
}

.search-container {
  position: relative;
}

.search-container input {
  width: 100%;
  box-sizing: border-box;
  background: #ffffff;
  border: 1px solid #dddfe3;
  border-radius: 4px;
  height: 48px;
  padding: 10px 40px 10px 18px;
  font-size: 14px;
  line-height: 14px;
  font-family: "Poppins", sans-serif;
  color: #979797;
}

.search-container button {
  margin: 0;
  padding: 0;
  border: 0;
  background: url("../assets/images/search_icon.png");
  width: 18px;
  height: 18px;
  position: absolute;
  top: 15px;
  right: 15px;
}

.plan-box {
  background: #f2fafa;
  border-radius: 4px;
  padding: 18px;
  font-family: "Poppins", sans-serif;
  margin: 0 0 12px 0;

  h4 {
    font-weight: 600;
    font-size: 24px;
    line-height: 24px;
    text-transform: capitalize;
    color: #151852;
    margin: 0 0 18px 0;
    padding: 0;
  }

  ul {
    margin: 0;
    padding: 0;
    clear: both;

    li {
      margin: 0 8px 5px 0;
      padding: 0;
      list-style: none;
      display: block;
      vertical-align: middle;

      input {
        box-sizing: border-box;
        border-radius: 4px;
        font-size: 12px;
        line-height: 12px;
        text-transform: capitalize;
        color: #979797;
      }
    }

    li.add-btn {
      margin: 18px 0 0;
      padding: 0;
    }
  }
}

.plan-box {
  ul {
    margin: 0;
    padding: 0;

    li {
      margin: 0 0 12px 0;
    }

    li.complete {
      padding-left: 24px;
      border: 0;
      background: url("../assets/images/complete_icon.svg");
      background-repeat: no-repeat;
      background-position: 0 50%;
      background-size: 16px 16px;
      font-size: 16px;
      line-height: 24px;
      color: #505050;
      font-weight: 400;
    }

    li.in-progress {
      padding-left: 24px;
      border: 0;
      background: url("../assets/images/in_progress_icon.svg");
      background-repeat: no-repeat;
      background-position: 0 50%;
      background-size: 16px 16px;
      font-size: 16px;
      line-height: 24px;
      color: #505050;
      font-weight: 400;
    }

    li.pending {
      padding-left: 24px;
      border: 0;
      background: url("../assets/images/pending_icon.svg");
      background-repeat: no-repeat;
      background-size: 16px 16px;
      background-position: 0 50%;
      font-size: 16px;
      line-height: 24px;
      color: #505050;
      font-weight: 400;
    }
  }

  &:last-of-type {
    margin: 0;
  }
}

.full-box {
  background: #ffffff;
  border: 1px solid #dcf0f0;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 24px 18px 18px;
  margin: 0 0 12px 0;

  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h3 {
      font-weight: 600;
      font-size: 24px;
      line-height: 24px;
      text-transform: capitalize;
      color: #33ca8f;
      font-family: "Poppins", sans-serif;
      margin: 0;
    }

    .date {
      font-size: 18px;
      line-height: 24px;
      text-align: right;
      text-transform: capitalize;
      color: #151852;
      font-family: "Poppins", sans-serif;
      font-weight: 400;
      background: url("../assets/images/arrow.svg");
      background-repeat: no-repeat;
      background-position: 100% 50%;
      padding-right: 20px;
    }
  }
}

.full-box p.cnt {
  font-size: 16px;
  line-height: 24px;
  color: #505050;
  margin: 18px 0 12px 0;
}

.button-section {
  padding: 6px 0 0;
}

.button-section button {
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  background: #ec5f43;
  font-family: "Poppins", sans-serif;
  border-radius: 100px;
  width: 148px;
  height: 48px;

  &:hover {
    background: #000;
    color: #fff;
  }
}

.add-btn {
  position: relative;
}

.add-btn input {
  background: #ffffff;
  border: 1px solid #dddfe3;
  box-sizing: border-box;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
  height: 42px;
  padding: 0 95px 0 18px;
  font-size: 14px;
  line-height: 14px;
  color: #979797;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}

.add-btn a {
  margin: 0;
  padding: 0;
  background: #33ca8f;
  border: 1px solid #33ca8f;
  box-sizing: border-box;
  border-radius: 4px;
  font-weight: 600;
  font-size: 10px;
  line-height: 10px;
  text-transform: uppercase;
  color: #ffffff;
  width: 74px;
  height: 30px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 6px;
  right: 6px;
}

.remove-bg {
  background-image: none !important;
  z-index: 1;
  max-width: 90%;
}

.save-title-btn-padding {
  padding: 0 18px 18px 18px;
}

.title-input {
  text-align: left;
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  color: #33ca8f;
  border: none;
  padding: 0;
}

.react-datepicker-wrapper {
  width: auto !important;
}

.collapse-icon {
  background-image: url("../assets/images/arrow_v2_black.svg");
  background-repeat: no-repeat;
  // background-position: 96% 50%;
  background-position: center right;
  position: absolute;
  right: 20;
  width: 100%;
  z-index: 0;
  height: 100%;
}

.collapse-icon.collapsed {
  background-image: url("../assets/images/arrow_v1_black.svg");
}

.btn-rect {
  margin: 0;
  padding: 0;
  background: #33ca8f;
  border: 1px solid #33ca8f;
  box-sizing: border-box;
  border-radius: 4px;
  font-weight: 600;
  font-size: 10px;
  line-height: 10px;
  text-transform: uppercase;
  color: #ffffff;
  width: 74px;
  height: 30px;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.btn-disabled {
  background-color: #acacac;
  border-color: #acacac;
  color: #000
}

.btn-rect span {
  padding: 1px 0 0 1px;
  border: 0;
  // background: url(/static/media/plus_icon.1cf2f7ed.svg);
  background-repeat: no-repeat;
  background-size: 10px 10px;
  background-position: 0 50%;
  font-weight: 600;
  font-size: 10px;
  line-height: 10px;
  text-transform: uppercase;
  color: #ffffff;
  display: flex;
  align-items: center;
}

.add-btn a span {
  padding: 1px 0 0 16px;
  border: 0;
  background: url("../assets/images/plus_icon.svg");
  background-repeat: no-repeat;
  background-size: 10px 10px;
  background-position: 0 50%;
  font-weight: 600;
  font-size: 10px;
  line-height: 10px;
  text-transform: uppercase;
  color: #ffffff;
  display: flex;
  align-items: center;
}

.add-plan-section ul {
  margin: 0;
  padding: 0;
  display: block;

  li {
    margin: 0 4px 4px 0;
    padding: 8px 10px;
    float: left;
    list-style: none;
    font-weight: 400;
    font-size: 10px;
    line-height: 10px;
    text-align: center;
    color: #33ca8f;
    border: 1px solid #33ca8f;
    box-sizing: border-box;
    border-radius: 100px;
    cursor: pointer;
  }
}

.add-plan-section .button-section {
  clear: both;
  text-align: right;
  margin: 0 0 12px 0;
  padding: 20px 0 0;
}

.no-content-part .content-part p {
  font-size: 36px;
  line-height: 36px;
  text-align: center;
  text-transform: capitalize;
  color: #151852;
  font-weight: 400;
  margin: 48px 0 36px 0;
}

.no-content-part .content-part .button-section {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}

.image-part .no-content-image {
  width: 50%;
  height: auto;
}

.image-part {
  display: flex;
  justify-content: center;
}

.descriptionList {
  padding: 10px;
}

// accordion design
.goal-content-wrapper {
  .accordion-wrapper {
    margin-bottom: 35px;

    .card {
      background: #ffffff;
      border: 1px solid #d9efe7 !important;
      box-sizing: border-box;
      border-radius: 4px !important;
      margin: 0 0 8px 0;
      padding: 0;

      .card-head {
        cursor: pointer;
      }

      h2 {
        text-align: left;
        font-weight: 600;
        font-size: 24px;
        line-height: 24px;
        color: #33ca8f;
        padding: 24px 18px 18px 18px;
        font-family: "Poppins", sans-serif;
        margin: 0;
        background-image: url("../assets/images/arrow_v2_black.svg");
        background-repeat: no-repeat;
        background-position: 96% 50%;
        cursor: pointer;
        &.collapsed {
          background-image: url("../assets/images/arrow_v1_black.svg");
        }

        img {
          cursor: pointer;
        }
      }

      .card-body {
        padding: 0 18px 18px;
      }

      .chips-drodown-part {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        .chips-part {
          display: flex;
          max-width: 100%;

          ul {
            list-style-type: none;
            display: flex;
            margin: 0;
            flex-wrap: wrap;

            li {
              border: 1px solid #33CA8F;
              border-radius: 100px;
              padding: 10px 24px;
              font-family: "Poppins", sans-serif;
              font-weight: 500;
              font-size: 10px;
              line-height: 10px;
              text-align: center;
              color: #33CA8F;
              margin: 0 4px 8px 0px;
            }
          }

          .chips-btn {
            border: 1px solid #5F6477;
            border-radius: 100px;
            padding: 3px;
            width: 30px;
            height: 30px;
            text-align: center;
            background-color: transparent;
          }
        }

        .date-time-dropdown {
          .dropdown {
            background: #E7F9F7;
            border-radius: 4px;
            height: 42px;

            a {
              font-weight: 500;
              font-size: 16px;
              line-height: 16px;
              text-transform: capitalize;
              color: #151852;

              &:hover {
                color: #212529 !important;
              }
            }

            .dropdown-menu {
              &.custom-menu {
                z-index: 900000000;
                transform: translate3d(0px, 40px, 0px);
                width: 100%;
                border-radius: 0;
                border: 1px solid rgb(226, 244, 242);
                box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.12);
                margin: 0px;
                padding: 0px;

                ul {
                  margin-bottom: 0;

                  li {
                    background-color: #fff;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 16px;
                    text-transform: capitalize;
                    color: #151852;
                    border: 0;
                    outline: 0;
                    padding: 12px 15px;
                    -webkit-transition: all 0.2s;
                    transition: all 0.2s;
                    width: 100%;

                    &:hover,
                    &.active {
                      background-color: #e7f9f7;
                    }
                  }
                }
              }
            }
          }
        }
      }

      p.content {
        font-size: 16px;
        line-height: 24px;
        color: #505050;
        margin: 18px 0;
        font-family: "Poppins", sans-serif;

        img {
          cursor: pointer;
        }
      }

      .full-divide-block {
        font-family: "Poppins", sans-serif;

        .full-box {
          padding: 18px;
          background: #F2FAFA;
          border-radius: 4px;
          border: 1px solid #F2FAFA;

          .header-content {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;

            h5 {
              font-weight: 600;
              font-size: 24px;
              line-height: 24px;
              text-transform: capitalize;
              color: #151852;
              font-family: "Poppins", sans-serif;
              margin-bottom: 0;
              max-width: 48%;
            }

            .right-content {
              display: flex;
              align-items: center;

              .plan,
              .coins {
                margin-right: 18px;
              }

              h6 {
                font-weight: 600;
                font-size: 14px;
                line-height: 14px;
                text-transform: capitalize;
                color: #151852;
                margin-bottom: 0;
              }

              .icon {
                margin-right: 6px;
              }

              button {
                background: #FFFFFF;
                border: 1px solid #DFFFF3;
                box-sizing: border-box;
                border-radius: 100px;
                font-weight: 600;
                font-size: 12px;
                line-height: 12px;
                color: #151852;
                width: 90px;
                height: 35px;
              }
            }
          }

          .event-log-part {
            margin: 26px 0;

            .goal-main-content {
              display: flex;
              justify-content: space-between;
              margin-bottom: 16px;
              align-items: flex-start;

              &:last-child {
                margin-bottom: 0;
              }
            }

            .switch-goal-part {
              display: flex;
              align-items: flex-start;

              h6 {
                font-size: 16px;
                line-height: 24px;
                color: #505050;
                margin-bottom: 0;
                max-width: 80%;
                margin-right: 0.5rem;

                &.complete-process {
                  text-decoration-line: line-through;
                }
              }

              .day-part {
                &.pending {
                  background-color: #ec5f43;
                }

                &.progress {
                  background-color: #ffc133;
                }

                &.complete {
                  background-color: #33ca8f;
                }

                background-color: #ec5f43;
                color: white;
                width: 70px;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                margin-right: 0.5rem;
                border-radius: 16px;
                height: 25px;
                margin-top: 2px;
              }

              .day-part h6 {
                color: white;
                font-size: 14px;
                text-align: center;
                margin-right: 0;
              }
            }

            .calendar-part {
              display: flex;
              justify-content: flex-end;
              align-items: center;

              .more-part {
                background: transparent;
                padding: 0;
                margin: 0;

                a {
                  padding: 0;

                  img {
                    width: 28px;
                  }
                }

                .custom-menu {
                  min-width: 160px !important;
                  left: -135px !important;
                }

                ul {
                  margin-bottom: 0;
                  padding-left: 0.5rem;

                  li {
                    margin: 0;
                    padding: 12px 0;
                    list-style: none;
                    font-size: 14px;
                    line-height: 14px;
                    display: flex;
                    align-items: center;
                    letter-spacing: 0.01em;
                    color: #151852;
                    font-weight: 400;
                    justify-content: space-between;
                    cursor: pointer;
                    text-transform: capitalize;
                  }
                }

              }

              p {
                font-size: 12px;
                line-height: 24px;
                color: #151852;
                margin-bottom: 0;
                margin-right: 15px;
                text-transform: uppercase;
                width: 120px;
                text-align: right;
                display: flex;
                justify-content: space-between;

                &.date {
                  display: block;
                }

                .day-name {
                  cursor: pointer;
                  display: block;
                  height: 26px;
                  width: 28px;
                  text-align: center;
                  margin-right: 4px;
                  border: 1px solid #33ca8f;
                  border-radius: 2px;

                  &:last-child {
                    margin-right: 0;
                  }

                  &.active,
                  &:hover {
                    color: #fff;
                    background-color: #33ca8f;
                  }
                }
              }

              .inc-dec-part {
                display: flex;
                align-items: center;

                .btn {
                  width: 25px;
                  height: 25px;
                  border-radius: 50%;
                  margin: 0;
                  padding: 0;
                  text-align: center;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  border: 1px solid #5F6477;
                  background-color: transparent;
                }

                .input-no {
                  width: 50px;
                  padding: 0;
                  margin: 0;
                  text-align: center;
                  border: 0;
                  background: transparent;
                  font-weight: 500;

                  &::placeholder {
                    color: #0d1151;
                  }
                }
              }

              .btn {
                width: 28px;
                height: 28px;
                left: 626px;
                top: 690px;
                background-color: #FFFFFF;
                // border: 1px solid #DFFFF3;
                border: 1px solid #01024f;
                background-color: #01024f;
                border-radius: 100px;
                margin-right: 15px;
                background-repeat: no-repeat;
                background-position: center;
                z-index: 0;
                &:last-child {
                  margin-right: 0;
                }

                &:hover {
                  &.delete-button {
                    background-color: #EC5F43;
                    background-image: url("../assets/images/delete-icon-white.svg");
                  }

                  &.refresh-button {
                    background-color: #0BB8C8;
                    background-image: url("../assets/images/refresh-white.svg");
                    border-color:#0BB8C8;
                  }

                  &.calendar-button {
                    background-color: #909295;
                    background-image: url("../assets/images/calendar-white.svg");
                  }

                  // &.non-schedule-button{
                  //   background-color:#0BB8C8;
                  //   background-image: url("../assets/images/schedule-white.png");
                  // }
                }

                // &:focus{
                //   &.non-schedule-button{
                //     background-color:#0BB8C8;;
                //     background-image: url("../assets/images/schedule-white.png");
                //   }
                // }
                &.delete-button {
                  background-image: url("../assets/images/delete-icon.svg");
                }

                &.refresh-button {
                  background-image: url("../assets/images/refresh-white.svg");
                }

                &.refresh-button.active {
                  background-color: #0BB8C8;
                  border-color:#0BB8C8;
                  background-image: url("../assets/images/refresh-white.svg");
                }

                &.calendar-button {
                  background-image: url("../assets/images/calendar.svg");
                }

                &.non-schedule-button {
                  // background-image: url("../assets/images/schedule.png");
                  // background-image: url("../assets/images/s-cloud-blue.png");
                  background-image: url("../assets/images/s-cloud-white.png");
                  // background-image: url("../assets/images/cloud.png")
                  // background-image: url("../assets/images/calendar-sync.png");
                }


                &.non-schedule-button {
                  z-index: 0;

                  &.not-loggedIn {
                    background-image: url("../assets/images/s-cloud-white.png");
                    background-color: #ef6751;
                    border-color: #ef6751;
                  }

                  &.not-synce {
                    background-color: #0BB8C8;
                    background-image: url("../assets/images/s-cloud-white.png");
                    border-color: #0BB8C8;
                  }

                  &.active {
                    background-color: #0BB8C8;
                    // background-image: url("../assets/images/schedule-white.png");
                    background-image: url("../assets/images/s-cloud-white.png");
                    // background-image: url("../assets/images/cloud-white.png");
                    // background-image: url("../assets/images/calendar-sync.png");
                    border-color: #0BB8C8;
                  }

                }
              }
            }
          }
        }
      }
      .goal-task-text{
        position: relative;
        .task-part-tooltip{
          font-size: 14px;
          padding: 6px 16px 8px;
          text-align: center;
          background-color: #fff;
          border: 1px solid #fff;
          width: 260px;
          min-width: 260px;
          position: absolute;
          left: 8px;
          z-index: 2;
          filter: drop-shadow(rgba(0, 0, 0, 0.3) 0px 0px 3px);
          box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
          display: none;
          &.tooltip-top {
            &::before{
              content: '';
              position: absolute;
              display: block;
              width: 0px;
              left: 50%;
              top: 5px;
              border: 8px solid transparent;
              border-top: 0;
              border-bottom: 8px solid #fff;
              transform: translate(-50%, calc(-100% - 5px));
            }
          }
          p{
            font-size: 14px !important;
            text-transform: initial !important;
            text-align: center !important;
            color: #212529 !important;
            width: 100% !important;
            margin-bottom: 0;
          }
        }
        &:hover {
          .task-part-tooltip{
              &.tooltip-click-show{
                  display: block;
              }
          }
          &.complete-process{
            .task-part-tooltip{
              display: none;
            }
          }
        }
        .word-break{
          word-break: break-word;
        }
      }
      .footer-btn {
        margin-top: 18px;

        .btn {
          font-weight: 600;
          font-size: 12px;
          line-height: 12px;
          text-align: center;
          text-transform: uppercase;
          padding: 12px 18px;
          font-family: "Poppins", sans-serif;
          background: #FF2A00;
          color: white;
          border-radius: 100px;

          &:hover {
            background-color: #000;
          }

          &.outline-btn {
            background: #FFFFFF;
            border: 1px solid #33CA8F;
            margin-right: 8px;
            color: #33CA8F;

            &:hover {
              color: #000;
              border: 1px solid #000;
            }
          }

          &.filled-btn {
            background: #33CA8F;
            border: 1px solid #33CA8F;
            margin-right: 8px;
            color: #FFF;

            &:hover {
              border: 1px solid #FFF;
              background-color: #000;
            }
          }
        }
      }
    }
  }
}

.hidden-textarea {
  border: none;
  width: 90%;
}

.z-index-0 {
  z-index: 0 !important;
}


.description-hidden {
  transition: .3s;
  line-height: 1.5em;
  height: 12em;
  /* height is 2x line-height, so two lines will display */
  overflow: hidden;
}

.show-more {
  // height: auto;
  // text-overflow: inherit;
  transition: 0.3s;
}

// three way toggle switch
.three-way-toggle {
  margin-top: 4px;

  .toggle-container {
    display: flex;
    align-items: center;
    width: 60px;
    height: 18px;
    background: #FFFFFF;
    border: 1px solid #D9EFE7;
    border-radius: 100px;
    position: relative;
    margin-right: 8px;
  }

  input {
    display: none;
  }

  .switch-part-tooltip {
    font-size: 14px;
    padding: 6px 16px 8px;
    text-align: center;
    background-color: #fff;
    border: 1px solid #fff;
    width: 230px;
    max-width: 275px;
    position: absolute;
    top: 29px;
    z-index: 2;
    filter: drop-shadow(rgba(0, 0, 0, 0.3) 0px 0px 3px);
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
    display: none;

    &.pending {
      left: -38px;
    }

    &.complete {
      left: -54px;
    }

    &.inprogress {
      left: -57px;
    }

    p {
      margin-bottom: 0;
      font-size: 14px;
      color: #212529 !important;
    }

    &.tooltip-top {
      &::before {
        content: '';
        position: absolute;
        display: block;
        width: 0px;
        left: 20%;
        top: 5px;
        border: 8px solid transparent;
        border-top: 0;
        border-bottom: 8px solid #fff;
        transform: translate(-50%, calc(-100% - 5px));
      }
      &.pending{
        &::before{
          left: 21%;
        }
      }
      &.inprogress {
        &::before{
          left: 38%;
        }
      }
      &.complete{
        &::before{
          left: 45%;
        }
      }
    }
  }

  label {
    padding: 10px;
    width: 100%;
    cursor: pointer;
    position: relative;
  }

  .round {
    position: absolute;
    top: -4px;
    left: 0;
    right: 0;
    bottom: 0;
    width: 24px;
    height: 24px;
    border-radius: 100px;
    transition: all .3s ease;
    border: 0;
  }
  

  .checkbox1:checked ~ .round {
    transform: translateX(0px);
    background: #EC5F43;
  }

  .checkbox2:checked ~ .round {
    transform: translateX(75%);
    background: #FFC133;
  }

  .checkbox3:checked ~ .round {
    transform: translateX(155%);
    background: #33CA8F;
  }

  .checkbox1.checkpart~.round{
    transform: translateX(0px);
    background: #EC5F43;
  }
  .checkbox2.checkpart~.round{
    transform: translateX(75%);
    background: #FFC133;
  }
  .checkbox3.checkpart~.round{
    transform: translateX(155%);
    background: #33CA8F;
  }

  &.tooltip-click-show {
    &:hover {
      .switch-part-tooltip {
        display: block;
      }
    }
  }
}

body {
  background: #fff;
  margin: 0;
}

.bottom-bar-position {
  position: fixed;
  bottom: 0;
  left: 50;
  z-index: 9;
}

.tab-container {
  background: transparent;
  margin: 0;
  padding: 0;
  max-height: 35px;
}

ul.tabs-custom {
  margin: 0;
  list-style-type: none;
  line-height: 35px;
  max-height: 35px;
  overflow: hidden;
  display: inline-block;
  padding-right: 20px;
  padding-left: 20px;
}

ul.tabs-custom>li.active {
  z-index: 2;
  background: #fff;
}

ul.tabs-custom>li:before {
  border-color: transparent #efefef transparent transparent;
  box-sizing: content-box !important;
}

ul.tabs-custom>li.active:before {
  border-color: transparent #fff transparent transparent;
  box-sizing: content-box !important;
}


ul.tabs-custom>li.active:after {
  border-color: transparent transparent transparent #fff;
  box-sizing: content-box !important;
}

ul.tabs-custom>li:after {
  border-color: transparent transparent transparent #efefef;
  box-sizing: content-box !important;
}

ul.tabs-custom>li {
  float: right;
  margin: 5px -10px 0;
  border-top-right-radius: 25px 170px;
  border-top-left-radius: 20px 90px;
  padding: 0 30px 0 25px;
  height: 170px;
  background: #ddd;
  position: relative;
  /* box-shadow: 0 10px 20px rgba(0,0,0,.5);*/
  max-width: 200px;
}

ul.tabs-custom>li>a {
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: none;
  color: #222;
}

ul.tabs-custom>li:before,
ul.tabs-custom>li:after {
  content: '';
  background: transparent;
  height: 20px;
  width: 20px;
  border-radius: 100%;
  border-width: 10px;
  top: 0px;
  border-style: solid;
  position: absolute;
  box-sizing: content-box !important;
}

ul.tabs-custom>li:before {
  border-color: transparent #ddd transparent transparent;
  -webkit-transform: rotate(48deg);
  left: -23px;
  box-sizing: content-box !important;
}

ul.tabs-custom>li:after {
  border-color: transparent transparent transparent #ddd;
  -webkit-transform: rotate(-48deg);
  right: -17px;
  box-sizing: content-box !important;

}

/* Clear Fix took for HTML 5 Boilerlate*/

.clearfix:before,
.clearfix:after {
  content: "";
  display: table;
}

.clearfix:after {
  clear: both;
}

.clearfix {
  zoom: 1;
}

// tips part design changes
.goal-content-wrapper .accordion-wrapper .card-body {
  position: relative;
}

.tips-part {
  position: absolute;
  right: 18px;
  bottom: 11px;
  border: 1px solid #d9efe7;
  border-radius: 3px;
  max-width: 45%;

  &.add-form-width {
    max-width: 26%;
  }
}

.tips-part ul {
  padding: 10px 30px;
  margin-bottom: 0;
}

.tips-part ul li {
  font-size: 13px;
  line-height: 22px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  color: #505050;
}

.tips-part .tips-block .tips-title {
  margin-bottom: -5px;
  margin-left: 14px;
  margin-top: 10px;
  font-size: 15px;
  color: #34ca8f;
}

.right-arrow {
  width: 60px;
  height: 60px;
  border: 0;
  margin-top: 10px;
  background: rgb(245, 245, 245);
  outline: 0;

  &:focus {
    border: 0;
    outline: 0;
  }
}

@media (min-width: 900px) {
  .pap-dropdown-title {
    display: flex !important;
    grid-column-gap: 0em;
  }
}


// for mobile bottom bar design
@media screen and (max-width:991px){
  .customSlider-tab-part .nav-item .nav-link{
    color: #222 !important;
  }
}
@media screen and (max-width:767px) {
  .mobile-devices-bottom-part {
    &.bottom-bar-position {
      left: 0;
      bottom: -1px;
      height: 46px;
      width: 100%;
      display: flex;
      justify-content: center;
    }

    .tab-container {
      width: 100%;
      height: 50px;
      max-height: 50px;
    }

    .tabs-custom {
      width: 100%;
      display: flex;
      justify-content: center;
      text-align: center;
      font-weight: 500;
      height: 46px;
      max-height: 46px;

      li {
        width: 80%;
        max-width: 80%;

        &::before,
        &::after {
          top: -2px;
          height: 108%;
        }

        &::before {
          left: -23px;
        }

        &::after {
          right: -20px;
        }

        a {
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 3px 0;
          color: #222 !important;
        }
      }
    }
  }

  .sliding-panel-container {
    &.active {
      .panel-content {
        overflow: hidden !important;
      }

      .tabs-custom {
        margin-left: 0 !important;
        width: 100%;
        display: flex;
        justify-content: center;
        height: 50px;
        max-height: 50px;
        position: fixed;
        top: 45.7vh;

        li {
          display: flex;
          justify-content: center;
          width: 80%;
          max-width: 80%;

          a {
            font-weight: 500;
            margin-top: 4px;
          }
        }
      }
    }

    .demo-container {
      overflow: scroll;
      height: 100%;
      max-height: 100%;

      .right-arrow {
        width: 45px;
        height: 45px;
        margin-right: 10px;
        z-index: 99;
      }

      .mobile-text-up {
        margin-top: -50px;

        img {
          width: 80%;
        }

        h3 {
          font-size: 1.4rem;
        }
      }

      .link-button-section {
        padding-right: 18px;
        grid-gap: 5px;
        margin: 15px 0;

        a {
          padding: 10px 5px !important;
          width: 20%;
          text-align: center;
        }
      }
    }
  }
}

@media screen and (width:412px) {
  .sliding-panel-container.active .tabs-custom {
    top: 46.2vh;
  }
}




@-webkit-keyframes rotating

/* Safari and Chrome */
  {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.rotating {
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
  background-color: lightgray !important;
  background-image: url("../assets/images/schedule.png") !important;
  opacity: 0.4;
  pointer-events: none;
  cursor: not-allowed;
}

.disabled {
  background-color: lightgray !important;
  border-color: lightgray !important;
  background-image: url("../assets/images/schedule.png") !important;
  pointer-events: none;
  opacity: 0.4;
}

.ribbion-animation {
  position: relative;

  .panel-content {
    height: 100%;
    overflow: auto;
  }
}




.panel-content-part{
  padding: 15px;
}
.sliding-panel-container {
  &.active {
    .panel-content {
      overflow: hidden !important;

    }
  }
}


.sucess-part-outcome {
margin: auto;
  img {
    width: 12%;
    display: block;
    margin: auto;
  }
}


.throphy-section-outcome {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 60%;
}

.sucess-text-part {
  align-items: flex-start;
  justify-content: flex-start;

}
.steps-text-part {
  display: flex;
  margin-bottom: 1rem;
  align-items: center;

  span {
    font-weight: 600;
    color: #0d1151;
    font-size: 18;
    margin-left: 1rem;
  }

}

.outcome-bottom-container {
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;

  .links-section-outcome{
    padding-top: 5%;
  }
  
  @media screen and (max-width: 991px) {
    flex-wrap: wrap;
    overflow: auto;

    .throphy-section-outcome {
      margin-bottom: 3rem;
      margin-top: 2rem;
    }

    .steps-text-part:last-child {
      margin-bottom: 5rem;
    }

  }
}

.sucess-text-part-outcome {
  // margin-left: -20px;

  h5 {
    text-align: center;
    font-size: 36px;
    font-weight: 500;
    letter-spacing: 1px;
    color: #0d1151;
  }

  p {
    text-align: center;
    color: #0d1151;
    font-size: 18px;
    margin: 1rem !important;

    b {
      color: #33ca8f;
    }
  }
}
.congratulations-text{
  color: #0d1151;
  font-size: 20px;
  font-weight: 600px;
}
.congratulation-slider {
  font-family: "Poppins", sans-serif;
  display: flex;
  align-items: center;
  height: 42vh;

  // height: 100%;
  .throphy-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  .table-trophy-part {
    align-items: flex-start;
  }

  .sucess-part {
    display: flex;
    justify-content: center;

    img {
      width: 40%;
    }
  }

  .button-part {
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      background-color: #33ca8f;
      border: 1px solid #33ca8f;
      border-radius: 23px;
      min-width: 155px;
      padding: 0.5rem;
      font-family: "Poppins", sans-serif;
      letter-spacing: 0.6px;

      &:hover,
      &:focus,
      &:active {
        background-color: #33ca8f;
        box-shadow: none;
      }
    }
  }

  .sucess-text-part {
    margin-left: -20px;

    h5 {
      text-align: center;
      font-size: 36px;
      font-weight: 500;
      letter-spacing: 1px;
      color: #0d1151;
    }

    p {
      text-align: center;
      color: #0d1151;
      font-size: 18px;
      margin: 1rem !important;

      b {
        color: #33ca8f;
      }
    }
  }

  .month-avg-part {
    margin-top: 0.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;

    .user-information {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-bottom: 6px;

      &:last-child {
        margin-bottom: 0;
      }

      p {
        margin-bottom: 0;
        font-weight: 500;
      }

      b {
        margin-left: 10px;
        color: #33CA8F;
        font-weight: 600;
      }
    }
  }

  .coins-table {
    width: 88%;

    thead {
      tr {
        background-color: #33ca8f3b;
      }

      th {
        padding: 10px 8px;
        text-transform: capitalize;
        font-weight: 600;
      }
    }

    tbody {
      td {
        padding: 10px 8px;
      }

      tr {
        &:nth-child(even) {
          background-color: #33ca8f3b;
        }
      }
    }

  }
}

@media only screen and (min-width: 1918px) {

  .sucess-part-outcome {
    img {
      width: 18%;
    }
  }

  .sucess-text-part-outcome {
    h5 {
      font-size: 42px;
    }

    p {
      font-size: 23px;
    }
  }

  .congratulation-slider {
    height: 42vh;

    // height: 100%;
    .sucess-part {
      img {
        width: 38%;
      }
    }

    .sucess-text-part {
      h5 {
        font-size: 42px;
      }

      p {
        font-size: 23px;
      }
    }

    .coins-table {
      thead {
        th {
          padding: 14px 8px;
        }
      }

      tbody {
        td {
          padding: 14px 8px;
        }
      }
    }
  }
}

@media screen and (max-width:1280px) {

  .sucess-part-outcome {
    img {
      width: 18%;
    }
  }

  .sucess-text-part-outcome {
    p {
      font-size: 17px;
    }
  }

  .congratulation-slider {
    .sucess-part {
      img {
        width: 46%;
      }
    }

    .sucess-text-part {
      p {
        font-size: 17px;
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .full-box .title .date {
    font-size: 14px;
  }

  .throphy-section-outcome {
    padding: 0 2rem;
  }

  .full-box .title h3 {
    font-size: 22px;
  }

  .goal-content-wrapper {
    .full {
      .right {
        ul {
          margin: 0 12px 0 0;
          padding: 0;
          display: flex;
          align-items: center;
          grid-gap: 12px;
        }

        .add-btn {
          width: 42%;
        }
      }
    }

    .accordion-wrapper {
      .card {
        .full-divide-block {
          .full-box {
            .header-content {
              flex-wrap: wrap;

              h5 {
                max-width: 100% !important;
              }

              .right-content {
                justify-content: flex-end;
                width: 100%;
                margin-top: 10px;
              }
            }

            .event-log-part {
              .goal-main-content {
                flex-wrap: wrap;

                .switch-goal-part {
                  width: 100%;
                }

                .calendar-part {
                  width: 100%;
                  margin-top: 8px;
                }
              }
            }
          }
        }
      }
    }
  }

  .sucess-part-outcome {
    img {
      width: 18%;
    }
  }

  .sucess-text-part-outcome {
    p {
      max-width: 84%;
      margin: 1rem auto !important;
    }
  }

  .congratulation-slider {
    height: 45vh;

    // height: 100%;
    .throphy-section {
      margin-bottom: 2.5rem;
    }

    .table-trophy-part {
      // margin-top: -60px;
    }

    .sucess-part {
      img {
        width: 35%;
      }
    }

    .sucess-text-part {
      p {
        max-width: 84%;
        margin: 1rem auto !important;
      }
    }

    .coins-table {
      width: 95%;
    }
  }
}

@media screen and (width:1024px) and (height:600px) {
  .congratulation-slider {
    height: 37vh;

    // height: 100%;
    .table-trophy-part {
      height: 100%;
      max-height: 100%;
      overflow: auto;
    }
  }
}


@media only screen and (max-width: 991px) {
  .goal-content-wrapper {
    .full {
      grid-template-columns: 1fr;
      grid-gap: 15px;
    }

    .accordion-wrapper {
      .card {
        .full-divide-block {
          display: grid;
          grid-template-columns: 1fr;
          grid-gap: 10px;
          align-items: flex-start;

          .full-box {
            .header-content {
              flex-wrap: nowrap;

              h5 {
                max-width: 50% !important;
                width: 50%;
              }

              .right-content {
                justify-content: flex-end;
                width: 50%;
                margin-top: 0;
              }
            }

            .event-log-part {
              .goal-main-content {
                flex-wrap: nowrap;
              }
            }
          }
        }
      }
    }
  }

  .no-content-part .content-part p {
    margin: 40px 0 28px 0;
  }

  .sucess-part-outcome {
    img {
      width: 18%;
    }
  }

  .sucess-text-part-outcome {
    h5 {
      font-size: 28px;
    }

    p {
      font-size: 15px;
      max-width: 100%;
      margin: 1rem 0 1rem 1rem !important;
    }
  }


  .congratulation-slider {
    height: 45vh;

    // height: 100%;
    .throphy-section {
      margin-bottom: 1.5rem;
    }

    .table-trophy-part {
      margin-top: -30px;
    }

    .sucess-part {
      img {
        width: 25%;
      }
    }

    .sucess-text-part {
      h5 {
        font-size: 28px;
      }

      p {
        font-size: 15px;
        max-width: 100%;
        margin: 1rem 0 1rem 1rem !important;
      }
    }

    .coins-table {
      width: 100%;
      font-size: 13px;
    }
  }
}

@media only screen and (max-width: 768px) {
  // .full-divide-block {
  //   display: grid;
  //   grid-template-columns: 1fr;
  //   grid-gap: 10px;
  //   align-items: flex-start;
  // }

  .full {
    display: grid;
    grid-template-columns: 1fr;
    justify-content: space-between;
    align-items: center;
    grid-gap: 15px;
    margin: 0 0 24px 0;
  }

  .dropdown.symptom .dropdown-menu.show {
    right: 0 !important;
    transform: inherit !important;
    top: 100% !important;
    z-index: 2;
  }
}

@media only screen and (max-width: 767px) {
  .full-box .title {
    display: block;
  }

  .full-box .title .date {
    font-size: 14px;
    line-height: 20px;
  }

  .full-box p.cnt {
    font-size: 14px;
    line-height: 20px;
  }

  .plan-box ul li {
    margin: 0 0 6px 0;
    width: 100%;
  }

  .plan-box ul li input {
    width: 100%;
  }

  .goal-content-wrapper {
    padding: 0 15px 30px;
  }

  .goal-content-wrapper .goal-content-inner h2 {
    margin: 0 0 15px 0;
    font-size: 25px;
    line-height: 30px;
  }

  .goal-content-wrapper {
    .full {
      .right {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;

        ul {
          display: block;

          li {
            margin: 4px 0;
          }
        }

        .add-btn {
          width: 100%;
          margin-top: 15px;
        }
      }
    }

    .accordion-wrapper {
      .card {
        .chips-drodown-part {
          flex-wrap: wrap;

          .chips-part {
            max-width: 100%;
          }

          .date-time-dropdown {
            margin-top: 10px;
            width: 100%;
          }
        }

        .full-divide-block {
          .full-box {

            .header-content {
              flex-wrap: wrap;

              h5 {
                max-width: 100% !important;
                width: 100%;
              }

              .right-content {
                justify-content: space-between;
                width: 100%;
                margin-top: 10px;

                .plan,
                .coins {
                  margin-right: 0;
                }
              }
            }

            .event-log-part {
              .goal-main-content {
                flex-wrap: wrap;
              }
            }
          }
        }
      }

    }
  }

  .goal-content-wrapper .goal-content-inner p,
  .goal-content-wrapper .goal-content-inner p strong {
    font-size: 14px;
    line-height: 24px;
  }

  .full-box {
    padding: 24px 15px 18px;
  }

  .full-box .title h3 {
    margin: 0 0 10px 0;
  }

  .plan-box h4 {
    font-size: 20px;
    line-height: 24px;
  }

  .plan-box {
    padding: 12px;
  }

  .plan-box ul li {
    font-size: 14px !important;
  }

  .no-content-part .content-part p {
    margin: 25px 0 18px 0;
    font-size: 18px;
  }

  .sucess-part-outcome {
    img {
      width: 18%;
    }
  }

  .sucess-text-part-outcome {
    h5 {
      font-size: 28px;
    }

    p {
      font-size: 15px;
      max-width: 100%;
      margin: 1rem 0 1rem 1rem !important;
    }
  }

  .congratulation-slider {
    overflow: auto;
    height: 45vh;

    .throphy-section {
      margin-bottom: 1.5rem;
    }

    .small-spacing {
      margin-bottom: 1.5rem;
    }

    .table-trophy-part {
      height: 100%;
      margin-top: 10px;
    }

    .sucess-part {
      img {
        width: 30%;
      }
    }

    .sucess-text-part {
      h5 {
        font-size: 28px;
      }

      p {
        font-size: 15px;
        max-width: 100%;
        margin: 1rem 0 1rem 1rem !important;
      }
    }

    .coins-table {
      width: 100%;
      font-size: 13px;
    }
  }
}

@media screen and (max-width: 380px) {
  .goal-content-wrapper .accordion-wrapper .card .footer-btn .btn{
    padding: 12px;
  }
}
.coins-tooltip-part {
  position: relative;
}

.coin-tooltip {
  position: absolute;
  left: -50px;
  span{
    font-size: 12px;
    line-height: 18px;
    margin-top: 9px;
    text-align: center;
    display: block;
    color: #646464;
  }
}

.up-arrow {
  display: inline-block;
  position: relative;
  border: 2px solid #33ca8f;
  text-decoration: none;
  border-radius: 4px;
  padding: 6px 16px 8px;
  margin-top: 16px;
  z-index: 2;
  min-width: 265px;
  background-color: #fff;
  filter: drop-shadow(rgba(0, 0, 0, 0.3) 0px 0px 3px);
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;

  p {
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
    font-family: "Poppins", sans-serif;
    color: #33ca8f;
    margin: 0 !important;
  }
}

.up-arrow:before {
  content: '';
  display: block;
  position: absolute;
  left: 95px;
  bottom: 100%;
  width: 0;
  height: 0;
  border: 12px solid transparent;
  border-bottom-color: #33ca8f;
}

.up-arrow:after {
  content: '';
  display: block;
  position: absolute;
  left: 98px;
  bottom: 100%;
  width: 0;
  height: 0;
  border: 9px solid transparent;
  border-bottom-color: white;
}



.customSlider-tab-part{
  margin-top: 1rem;
  margin-left: 1.5rem;
}

.customSlider-tab-part .nav-item{
  font-size: 14px;
  vertical-align: bottom;
  border: 2px solid #ddd;
  border-left: 0;
  padding: 0px 30px 8px;
  margin-right: -4px;
  display: inline-block;
  position: relative;
  z-index: 0;
  color: #ddd;
  background: white;
  z-index: 0;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  transition: all .2s;
  background-color: #ddd;
  border-bottom: 2px solid;
  border-color: #ddd;
  margin-bottom: 0;
  margin-top: -4px;
  z-index: 1;
  float: none;
  height: auto;
  max-width: none;
  margin: 0 -4px 0 0;
  background-color: #F5F5F5;
  border-color: #F5F5F5;
}

.customSlider-tab-part .nav-item::before{
  display: block;
    content: " ";
    position: absolute;
    top: 0px;
    height: 105%;
    width: 33px;
    background-color: #ffffff;
    left: -10px;
    transform: skew(-25deg, 0deg);
    border-left: 2px solid #ddd;
    border-radius: 5px 0 0 0;
    border-bottom: 2px solid #ddd;
    transition: all .1s;
    background-color: #ddd;
    border-bottom: 2px solid #ddd;
    border-left: 2px solid #ddd;
    border-width: 0px;
    background-color: #F5F5F5;
    border-color: #F5F5F5;
}
.customSlider-tab-part .nav-item::after{
  display: block;
    content: " ";
    position: absolute;
    top: 0px;
    height: 105%;
    width: 33px;
    background-color: #ffffff;
    right: -11px;
    transform: skew(25deg, 0deg);
    border-right: 2px solid #ddd;
    border-radius: 0px 5px 0 0;
    border-bottom: 2px solid #ddd;
    transition: all .1s;
    background-color: #ddd;
    border-bottom: 2px solid #ddd;
    border-right: 2px solid #ddd;
    border-width: 0;
    background-color: #F5F5F5;
    border-color: #F5F5F5;
}

.customSlider-tab-part .nav-item.active{
  z-index: 1;
    background-color: #ddd;
    // transform: scale(1.02);
    /* margin-bottom: 0px; */
    margin-top: -4px;
    margin-bottom: 0px;
    border-bottom: 2px solid #ddd;
    border-color: #ddd;
}
.customSlider-tab-part .nav-item.active::before{
  border-left: 2px solid #ddd;
  border-bottom: 2px solid #ddd;
  background-color: #ddd;
}
.customSlider-tab-part .nav-item.active::after{
  border-right: 2px solid #ddd;
  border-bottom: 2px solid #ddd;
  background-color: #ddd;
}

.nav-link-bold{
  font-weight: 600 !important;
}

.customSlider-tab-part .nav-item .nav-link{
  border: 0;
  transition: background-color .2s;
}
.customSlider-tab-part .nav-item .nav-link:hover{
  border: 0;
  border-color: transparent !important;
  color: #000;
}
.customSlider-tab-part .nav-item .nav-link.active{
  border: 0;
  border-color: transparent !important;
  color: white !important;
  background-color: #ddd;
  padding-top: 10px;
}